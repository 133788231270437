import { useAuth } from '@/core/auth';
import { DataTablePagination } from '@/shared/components/tables';
import {
  Badge,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  EmptyTableRow,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/shared/components/ui';
import { useTablePreferences } from '@/shared/services/table-preferences';
import type { ColumnDef, ColumnFiltersState, Row, SortingState, VisibilityState } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useIntl } from '@tiny-intl/react';
import { Clock, MoreHorizontal } from 'lucide-react';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

import type { DeploymentData } from '../schema/deployment-schema';

interface ActionCellProps {
  deployment: DeploymentData;
  onEdit: (deployment: DeploymentData) => void;
  onDelete?: (deployment: DeploymentData) => void;
}

const ActionCell = ({ deployment, onEdit, onDelete }: ActionCellProps) => {
  const { user } = useAuth();
  const { t } = useIntl();
  const isJuhUser = user?.role === 'juh-admin' || user?.role === 'juh-employee';
  const isJuhAdmin = user?.role === 'juh-admin';

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="size-8 p-0">
          <span className="sr-only">{t('navigation.openMenu')}</span>
          <MoreHorizontal className="size-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => onEdit(deployment)}>
          {isJuhUser ? t('common.edit') : t('orders.requestDeploymentChange')}
        </DropdownMenuItem>
        {isJuhUser && onDelete && (
          <DropdownMenuItem onClick={() => onDelete(deployment)} disabled={!isJuhAdmin}>
            {t('common.delete')}
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

interface DeploymentTableProps {
  deployments?: DeploymentData[] | null;
  onEdit?: (deployment: DeploymentData) => void;
  onDelete?: (deployment: DeploymentData) => void;
  isLoading?: boolean;
  pendingChangeRequests?: Record<string, { requestedBy: { name: string }; requestedAt: string }>;
}

export function DeploymentTable({
  deployments = [],
  isLoading,
  onEdit,
  onDelete,
  pendingChangeRequests,
}: DeploymentTableProps) {
  const { t } = useIntl();

  const tablePreferences = useTablePreferences({
    tableId: 'deployment-table',
    defaultPreferences: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
      sorting: [],
      columnFilters: [],
      columnVisibility: {},
    },
  });

  // Load initial preferences
  const preferences = tablePreferences.loadPreferences();

  const [sorting, setSorting] = useState<SortingState>(preferences.sorting || []);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(preferences.columnFilters || []);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(preferences.columnVisibility || {});
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState(
    preferences.pagination || {
      pageIndex: 0,
      pageSize: 10,
    },
  );

  // Save preferences when they change
  useEffect(() => {
    tablePreferences.savePreferences({
      pagination,
      sorting,
      columnFilters,
      columnVisibility,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, sorting, columnFilters, columnVisibility]);

  const columns: ColumnDef<DeploymentData>[] = [
    {
      accessorKey: 'title',
      header: t('orders.title'),
      cell: ({ row }: { row: Row<DeploymentData> }) => <div>{row.getValue('title')}</div>,
    },
    {
      accessorKey: 'description',
      header: t('orders.description'),
      cell: ({ row }: { row: Row<DeploymentData> }) => <div>{row.getValue('description')}</div>,
    },
    {
      accessorKey: 'startDate',
      header: t('orders.startDate'),
      cell: ({ row }: { row: Row<DeploymentData> }) => {
        const date = row.getValue('startDate') as string;
        return <div>{DateTime.fromISO(date).toFormat('dd.MM.yyyy HH:mm')}</div>;
      },
    },
    {
      accessorKey: 'endDate',
      header: t('orders.endDate'),
      cell: ({ row }: { row: Row<DeploymentData> }) => {
        const date = row.getValue('endDate') as string;
        return <div>{DateTime.fromISO(date).toFormat('dd.MM.yyyy HH:mm')}</div>;
      },
    },
    {
      id: 'crewChangesCount',
      header: t('orders.crewChangesCount'),
      cell: ({ row }: { row: Row<DeploymentData> }) => {
        const crewChanges = row.original.crewChanges;
        if (!crewChanges?.length) return null;
        return (
          <Badge variant="secondary" className="gap-1">
            {crewChanges.length}
          </Badge>
        );
      },
    },
    {
      id: 'changeRequest',
      header: t('orders.changeRequestStatus'),
      cell: ({ row }: { row: Row<DeploymentData> }) => {
        const changeRequest = pendingChangeRequests?.[row.original.id!];
        if (!changeRequest) return null;

        return (
          <Badge variant="secondary" className="gap-1">
            <Clock className="size-3" />
            <span>{t('orders.changeRequestPending')}</span>
          </Badge>
        );
      },
    },
    ...(onEdit
      ? [
          {
            id: 'actions',
            cell: ({ row }: { row: Row<DeploymentData> }) => (
              <ActionCell deployment={row.original} onEdit={onEdit} onDelete={onDelete} />
            ),
          },
        ]
      : []),
  ] as ColumnDef<DeploymentData>[];

  const table = useReactTable({
    data: (deployments ?? []) as DeploymentData[],
    columns,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                {t('common.loading')}
              </TableCell>
            </TableRow>
          ) : table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <EmptyTableRow colSpan={columns.length} />
          )}
        </TableBody>
      </Table>

      <DataTablePagination table={table} />
    </>
  );
}
