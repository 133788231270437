import type { GlobalMetadatum, JsonSchemaType, Order } from '@johanniter-offshore/backend';

import type { ActivityItem, DeploymentType } from './types';

// Helper to get regular order changes
export function getOrderChanges(currentVersion: Order, previousVersion: Order): ActivityItem['changes'] {
  const changes: ActivityItem['changes'] = [];

  // Track title changes
  if (currentVersion.title !== previousVersion.title) {
    changes.push({
      field: 'title',
      from: previousVersion.title,
      to: currentVersion.title,
    });
  }

  // Track orderNumber changes
  if (currentVersion.orderNumber !== previousVersion.orderNumber) {
    changes.push({
      field: 'orderNumber',
      from: previousVersion.orderNumber,
      to: currentVersion.orderNumber,
    });
  }

  // Track description changes
  if (currentVersion.description !== previousVersion.description) {
    changes.push({
      field: 'description',
      from: previousVersion.description,
      to: currentVersion.description,
    });
  }

  // Track date changes
  if (currentVersion.startDate !== previousVersion.startDate) {
    changes.push({
      field: 'startDate',
      from: previousVersion.startDate,
      to: currentVersion.startDate,
    });
  }

  if (currentVersion.endDate !== previousVersion.endDate) {
    changes.push({
      field: 'endDate',
      from: previousVersion.endDate,
      to: currentVersion.endDate,
    });
  }

  // Track organization changes
  if (currentVersion.organization !== previousVersion.organization) {
    changes.push({
      field: 'organization',
      from: previousVersion.organization,
      to: currentVersion.organization,
    });
  }

  // Track object changes
  if (currentVersion.object !== previousVersion.object) {
    changes.push({
      field: 'object',
      from: previousVersion.object,
      to: currentVersion.object,
    });
  }

  // Track metadata changes
  const currentMetadata = (currentVersion.metadata || {}) as Record<string, unknown>;
  const previousMetadata = (previousVersion.metadata || {}) as Record<string, unknown>;

  // Track dynamicMetadata changes
  const currentDynamicMetadata = (currentVersion.dynamicMetadataValues || {}) as Record<string, unknown>;
  const previousDynamicMetadata = (previousVersion.dynamicMetadataValues || {}) as Record<string, unknown>;

  // Get all unique keys from metadata objects
  const metadataKeys = new Set([...Object.keys(currentMetadata), ...Object.keys(previousMetadata)]);
  const dynamicMetadataKeys = new Set([
    ...Object.keys(currentDynamicMetadata),
    ...Object.keys(previousDynamicMetadata),
  ]);

  metadataKeys.forEach((key) => {
    const currentValue = currentMetadata[key];
    const previousValue = previousMetadata[key];

    if (currentValue !== previousValue) {
      changes.push({
        field: `metadata.${key}`,
        from: previousValue,
        to: currentValue,
      });
    }
  });

  dynamicMetadataKeys.forEach((key) => {
    const currentValue = currentDynamicMetadata[key];
    const previousValue = previousDynamicMetadata[key];

    if (currentValue !== previousValue) {
      changes.push({
        field: `dynamicMetadata.${key}`,
        from: previousValue,
        to: currentValue,
      });
    }
  });

  // Check if there are deployment changes
  const currentDeployments = currentVersion.deployments || [];
  const previousDeployments = previousVersion.deployments || [];

  // Check for changes in deployments (including crew changes)
  const hasDeploymentChanges = (() => {
    // If different number of deployments, there are changes
    if (currentDeployments.length !== previousDeployments.length) {
      return true;
    }

    // Map deployments by ID for easier comparison
    const currentDeploymentsMap = new Map(currentDeployments.map((d) => [d.id, d]));
    const previousDeploymentsMap = new Map(previousDeployments.map((d) => [d.id, d]));

    // Check if any deployment was added or removed
    for (const deployment of currentDeployments) {
      if (!previousDeploymentsMap.has(deployment.id)) {
        return true;
      }
    }

    for (const deployment of previousDeployments) {
      if (!currentDeploymentsMap.has(deployment.id)) {
        return true;
      }
    }

    // Check for changes in existing deployments
    for (const deployment of currentDeployments) {
      const previousDeployment = previousDeploymentsMap.get(deployment.id);
      if (!previousDeployment) continue;

      // Check deployment properties
      if (
        deployment.title !== previousDeployment.title ||
        deployment.startDate !== previousDeployment.startDate ||
        deployment.endDate !== previousDeployment.endDate ||
        deployment.description !== previousDeployment.description ||
        deployment.changeRequest !== previousDeployment.changeRequest
      ) {
        return true;
      }

      // Check crew changes
      const currentCrewChanges = deployment.crewChanges || [];
      const previousCrewChanges = previousDeployment.crewChanges || [];

      if (currentCrewChanges.length !== previousCrewChanges.length) {
        return true;
      }

      // Simple check for any crew change difference
      const currentCrewChangeIds = new Set(currentCrewChanges.map((c) => c.id));
      const previousCrewChangeIds = new Set(previousCrewChanges.map((c) => c.id));

      if (
        currentCrewChangeIds.size !== previousCrewChangeIds.size ||
        [...currentCrewChangeIds].some((id) => !previousCrewChangeIds.has(id)) ||
        [...previousCrewChangeIds].some((id) => !currentCrewChangeIds.has(id))
      ) {
        return true;
      }
    }

    return false;
  })();

  // If no changes were detected and there are no deployment changes, add a special "no changes" item
  if (changes.length === 0 && !hasDeploymentChanges) {
    changes.push({
      field: 'noChanges',
    });
  }

  return changes;
}

// Helper to detect deployment changes between two versions
export function getDeploymentChanges(currentVersion: Order, previousVersion: Order): ActivityItem[] {
  const activities: ActivityItem[] = [];
  const uniqueSuffix = Date.now().toString() + Math.random().toString(36).substring(2, 7);

  const currentDeployments = (currentVersion.deployments || []) as DeploymentType[];
  const previousDeployments = (previousVersion.deployments || []) as DeploymentType[];

  // Map deployments by ID for easier comparison
  const currentDeploymentsMap = new Map(currentDeployments.map((d) => [d.id, d]));
  const previousDeploymentsMap = new Map(previousDeployments.map((d) => [d.id, d]));

  // Check for added deployments
  currentDeployments.forEach((currentDeployment) => {
    const deploymentId = currentDeployment.id;
    if (!deploymentId) return;

    // Deployment was added
    if (!previousDeploymentsMap.has(deploymentId)) {
      // Create changes array to track deployment fields for the new deployment
      const addedChanges: ActivityItem['changes'] = [];

      // Add deployment details as changes
      addedChanges.push({
        field: 'title',
        to: currentDeployment.title,
      });

      addedChanges.push({
        field: 'startDate',
        to: currentDeployment.startDate,
      });

      addedChanges.push({
        field: 'endDate',
        to: currentDeployment.endDate,
      });

      if (currentDeployment.description) {
        addedChanges.push({
          field: 'description',
          to: currentDeployment.description,
        });
      }

      // Add crew changes if any exist
      if (currentDeployment.crewChanges && currentDeployment.crewChanges.length > 0) {
        currentDeployment.crewChanges.forEach((crewChange) => {
          addedChanges.push({
            field: 'crewChange-added',
            to: crewChange.dateTime,
          });
        });
      }

      activities.push({
        id: `deployment-added-${deploymentId}-${uniqueSuffix}`,
        type: 'deployment-added',
        timestamp: currentVersion.updatedAt || currentVersion.createdAt,
        user:
          typeof currentVersion.lastUpdatedBy === 'string'
            ? currentVersion.lastUpdatedBy
            : currentVersion.lastUpdatedBy?.id || null,
        deploymentId,
        deploymentTitle: currentDeployment.title,
        changes: addedChanges,
      });
      return;
    }

    // Existing deployment was updated
    const previousDeployment = previousDeploymentsMap.get(deploymentId);
    if (!previousDeployment) return;

    // Detect changes to deployment properties
    const changes: ActivityItem['changes'] = [];

    // Check for title changes
    if (currentDeployment.title !== previousDeployment.title) {
      changes.push({
        field: 'title',
        from: previousDeployment.title,
        to: currentDeployment.title,
      });
    }

    // Check for date changes
    if (currentDeployment.startDate !== previousDeployment.startDate) {
      changes.push({
        field: 'startDate',
        from: previousDeployment.startDate,
        to: currentDeployment.startDate,
      });
    }

    if (currentDeployment.endDate !== previousDeployment.endDate) {
      changes.push({
        field: 'endDate',
        from: previousDeployment.endDate,
        to: currentDeployment.endDate,
      });
    }

    // Check for description changes
    if (currentDeployment.description !== previousDeployment.description) {
      changes.push({
        field: 'description',
        from: previousDeployment.description,
        to: currentDeployment.description,
      });
    }

    // Check for crew changes
    const currentCrewChanges = currentDeployment.crewChanges || [];
    const previousCrewChanges = previousDeployment.crewChanges || [];

    // Map crew changes by ID for comparison
    const currentCrewChangesMap = new Map();
    const previousCrewChangesMap = new Map();

    currentCrewChanges.forEach((cc) => {
      if (cc.id) {
        currentCrewChangesMap.set(cc.id, cc);
      }
    });

    previousCrewChanges.forEach((cc) => {
      if (cc.id) {
        previousCrewChangesMap.set(cc.id, cc);
      }
    });

    // Crew changes added
    currentCrewChanges.forEach((cc) => {
      if (cc.id && !previousCrewChangesMap.has(cc.id)) {
        changes.push({
          field: 'crewChange-added',
          from: undefined,
          to: cc.dateTime,
        });
      }
    });

    // Crew changes removed
    previousCrewChanges.forEach((cc) => {
      if (cc.id && !currentCrewChangesMap.has(cc.id)) {
        changes.push({
          field: 'crewChange-removed',
          from: cc.dateTime,
          to: undefined,
        });
      }
    });

    // Crew changes modified
    currentCrewChanges.forEach((currentCC) => {
      if (!currentCC.id) return;

      const previousCC = previousCrewChangesMap.get(currentCC.id);
      if (previousCC && currentCC.dateTime !== previousCC.dateTime) {
        changes.push({
          field: 'crewChange-modified',
          from: previousCC.dateTime,
          to: currentCC.dateTime,
        });
      }
    });

    // Change request was added/removed/modified - skip here
    if (currentDeployment.changeRequest !== previousDeployment.changeRequest) {
      // This will be handled separately by change request detection logic
    }

    // If we detected changes, add an activity item
    if (changes.length > 0) {
      activities.push({
        id: `deployment-${deploymentId}-${uniqueSuffix}-${activities.length}`, // Add unique suffix and index
        type: 'deployment-updated',
        timestamp: currentVersion.updatedAt || currentVersion.createdAt,
        user:
          typeof currentVersion.lastUpdatedBy === 'string'
            ? currentVersion.lastUpdatedBy
            : currentVersion.lastUpdatedBy?.id || null,
        changes,
        deploymentId,
        deploymentTitle: currentDeployment.title,
      });
    }
  });

  // Check for removed deployments
  previousDeployments.forEach((previousDeployment) => {
    const deploymentId = previousDeployment.id;
    if (!deploymentId) return;

    // Deployment was removed
    if (!currentDeploymentsMap.has(deploymentId)) {
      activities.push({
        id: `deployment-removed-${deploymentId}-${uniqueSuffix}`,
        type: 'deployment-removed',
        timestamp: currentVersion.updatedAt || currentVersion.createdAt,
        user:
          typeof currentVersion.lastUpdatedBy === 'string'
            ? currentVersion.lastUpdatedBy
            : currentVersion.lastUpdatedBy?.id || null,
        deploymentId,
        deploymentTitle: previousDeployment.title,
      });
    }
  });

  return activities;
}

// Helper to detect change request changes between two versions
export function getChangeRequestChanges(currentVersion: Order, previousVersion: Order): ActivityItem[] {
  const activities: ActivityItem[] = [];
  // Generate a unique suffix for this batch of changes
  const uniqueSuffix = Date.now().toString() + Math.random().toString(36).substring(2, 7);

  const currentDeployments = (currentVersion.deployments || []) as DeploymentType[];
  const previousDeployments = (previousVersion.deployments || []) as DeploymentType[];

  // Map deployments by ID for easier comparison
  const currentDeploymentsMap = new Map(currentDeployments.map((d) => [d.id, d]));
  const previousDeploymentsMap = new Map(previousDeployments.map((d) => [d.id, d]));

  // Process all deployment IDs from both versions
  const allDeploymentIds = new Set([...currentDeployments.map((d) => d.id!), ...previousDeployments.map((d) => d.id!)]);

  allDeploymentIds.forEach((deploymentId) => {
    const currentDeployment = currentDeploymentsMap.get(deploymentId);
    const previousDeployment = previousDeploymentsMap.get(deploymentId);

    // Skip if this deployment doesn't exist in either version
    if (!currentDeployment && !previousDeployment) return;

    // Check for change request changes
    if (currentDeployment && previousDeployment) {
      const currentChangeReq = currentDeployment.changeRequest;
      const previousChangeReq = previousDeployment.changeRequest;

      // Change request was created (null -> id)
      if (!previousChangeReq && currentChangeReq) {
        activities.push({
          id: `change-request-created-${deploymentId}-${uniqueSuffix}-${activities.length}`, // Add unique suffix and index
          type: 'change-request-created',
          timestamp: currentVersion.updatedAt || currentVersion.createdAt,
          user:
            typeof currentVersion.lastUpdatedBy === 'string'
              ? currentVersion.lastUpdatedBy
              : currentVersion.lastUpdatedBy?.id || null,
          deploymentId,
          deploymentTitle: currentDeployment.title,
          changeRequestId: currentChangeReq,
          previousDeployment: { ...previousDeployment }, // Store the previous deployment state
        });
      }

      // Change request was replaced (id1 -> id2)
      else if (previousChangeReq && currentChangeReq && previousChangeReq !== currentChangeReq) {
        activities.push({
          id: `change-request-replaced-${deploymentId}-${uniqueSuffix}-${activities.length}`, // Add unique suffix and index
          type: 'change-request-created',
          timestamp: currentVersion.updatedAt || currentVersion.createdAt,
          user:
            typeof currentVersion.lastUpdatedBy === 'string'
              ? currentVersion.lastUpdatedBy
              : currentVersion.lastUpdatedBy?.id || null,
          deploymentId,
          deploymentTitle: currentDeployment.title,
          changeRequestId: currentChangeReq,
          previousDeployment: { ...previousDeployment }, // Store the previous deployment state
        });
      }

      // Change request was accepted/rejected (id -> null)
      else if (previousChangeReq && !currentChangeReq) {
        // This case is handled through change request hooks in the API, not version comparison
        // The actual activity for acceptance/rejection will be handled separately
      }
    }
  });

  return activities;
}

// Helper to extract deployment data with original values for change requests
export function extractDeploymentDataForChangeRequest(
  currentDeployment: DeploymentType,
  changeRequest: Record<string, unknown> & {
    originalDeployment?: DeploymentType;
    changes?: Record<string, unknown>;
  },
): {
  originalDeployment: DeploymentType;
  pendingChanges: Record<string, unknown>;
  changedFields: string[];
  crewChanges: Array<{
    id?: string;
    dateTime: string;
    action: 'add' | 'modify' | 'delete';
    originalDateTime?: string;
  }>;
} {
  // Extract the original deployment state from the change request
  // The original state is stored in the changeRequest.originalDeployment field
  // If not available, we'll use the current deployment as a fallback (not ideal)
  const originalDeployment: DeploymentType = changeRequest.originalDeployment || {
    ...currentDeployment,
  };

  // Extract the pending changes - these are the requested changes to apply
  const pendingChanges = { ...((changeRequest.changes as Record<string, unknown>) || {}) };

  // Determine which fields have actually changed
  const changedFields: string[] = [];

  // Cast objects to allow comparison
  const originalAsRecord = originalDeployment as unknown as Record<string, unknown>;
  const pendingAsRecord = pendingChanges as Record<string, unknown>;

  // Compare each field to see if it's different from the original
  Object.keys(pendingChanges).forEach((field) => {
    // Skip special fields
    if (field === 'id' || field === 'crewChanges') return;

    // If the field exists in both and values are different, it's changed
    if (
      field in originalAsRecord &&
      JSON.stringify(originalAsRecord[field]) !== JSON.stringify(pendingAsRecord[field])
    ) {
      changedFields.push(field);
    }

    // If the field only exists in pending changes, it's new
    if (!(field in originalAsRecord) && pendingAsRecord[field] !== undefined) {
      changedFields.push(field);
    }
  });

  // Process crew changes
  const crewChanges: Array<{
    id?: string;
    dateTime: string;
    action: 'add' | 'modify' | 'delete';
    originalDateTime?: string;
  }> = [];

  if (pendingChanges.crewChanges) {
    const requestedCrewChanges = pendingChanges.crewChanges as Array<{
      id?: string;
      dateTime: string;
      action?: 'add' | 'modify' | 'delete';
    }>;

    // If we have the original crew changes in the change request, use those
    const originalCrewChanges = originalDeployment.crewChanges || [];

    // Map of original crew changes by ID for quick lookup
    const originalCrewChangesMap = new Map(originalCrewChanges.map((crewChange) => [crewChange.id || '', crewChange]));

    // Set of IDs for tracking deleted crew changes
    const pendingCrewChangeIds = new Set(
      requestedCrewChanges.filter((change) => change.id && change.action !== 'delete').map((change) => change.id!),
    );

    // Find deleted crew changes (present in original but not in pending)
    originalCrewChanges.forEach((originalChange) => {
      if (originalChange.id && !pendingCrewChangeIds.has(originalChange.id)) {
        // This crew change exists in original but not in pending - it's been deleted
        crewChanges.push({
          id: originalChange.id,
          dateTime: originalChange.dateTime,
          action: 'delete',
        });
      }
    });

    // Process each requested crew change
    requestedCrewChanges.forEach((requestedChange) => {
      // New crew change
      if (!requestedChange.id) {
        crewChanges.push({
          dateTime: requestedChange.dateTime,
          action: 'add',
        });
        return;
      }

      // Explicit delete
      if (requestedChange.action === 'delete') {
        const original = originalCrewChangesMap.get(requestedChange.id);
        crewChanges.push({
          id: requestedChange.id,
          dateTime: original?.dateTime || requestedChange.dateTime,
          action: 'delete',
        });
        return;
      }

      // Modify existing crew change
      const original = originalCrewChangesMap.get(requestedChange.id);
      if (original && original.dateTime !== requestedChange.dateTime) {
        crewChanges.push({
          id: requestedChange.id,
          dateTime: requestedChange.dateTime,
          action: 'modify',
          originalDateTime: original.dateTime,
        });
        return;
      }

      // No actual changes - don't include
    });
  }

  return {
    originalDeployment,
    pendingChanges,
    changedFields,
    crewChanges,
  };
}

export function getFieldName(
  field: string,
  order?: Order,
  globalMetadata?: GlobalMetadatum,
  t?: (key: string) => string,
) {
  // Handle "no changes" case
  if (field === 'noChanges') {
    return t ? t('activity.fields.noChanges') : 'activity.fields.noChanges';
  }

  // Handle crew change fields
  if (field === 'crewChange-added' || field === 'crewChange-removed' || field === 'crewChange-modified') {
    return t ? t(`activity.changes.${field}`) : `activity.changes.${field}`;
  }

  // Handle regular fields
  if (!field.startsWith('metadata.') && !field.startsWith('dynamicMetadata.')) {
    return t ? t(`activity.changes.${field}`) : `activity.changes.${field}`;
  }

  // Handle metadata fields
  const [type, key] = field.split('.');
  let metadataSchema: JsonSchemaType | undefined;

  if (type === 'metadata') {
    // Get the appropriate global metadata schema based on order type
    if (order?.type === 'root-order') {
      metadataSchema = globalMetadata?.rootOrderMetadata;
    } else if (order?.type === 'article-rental') {
      metadataSchema = globalMetadata?.articleRentalOrderMetadata;
    } else if (order?.type === 'deployment-planning') {
      metadataSchema = globalMetadata?.deploymentPlanningMetadata;
    }
  } else if (type === 'dynamicMetadata' && order?.dynamicMetadataSchema) {
    metadataSchema = order.dynamicMetadataSchema;
  }

  // Return null for deleted metadata fields
  if (!metadataSchema?.properties[key]) {
    return null;
  }

  const description = metadataSchema.properties[key]?.description;
  if (description) {
    return description;
  }

  return t ? t(`activity.changes.${field}`) : `activity.changes.${field}`;
}
