import { Button } from '@/shared/components/ui/button';
import { Calendar } from '@/shared/components/ui/calendar';
import { Label } from '@/shared/components/ui/label';
import { Popover, PopoverContent, PopoverTrigger } from '@/shared/components/ui/popover';
import { TimePickerInput } from '@/shared/components/ui/time-picker-input';
import { cn } from '@/shared/utils';
import { useIntl } from '@tiny-intl/react';
import { CalendarIcon, Clock, X } from 'lucide-react';
import { DateTime } from 'luxon';
import { useRef } from 'react';

interface DateTimePickerProps {
  date: Date | undefined;
  setDate: (date: Date | undefined) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  disablePastDates?: boolean;
  showClearButton?: boolean;
  defaultMonth?: Date;
  minDate?: Date;
  maxDate?: Date;
}

export function DateTimePicker({
  date,
  setDate,
  placeholder,
  disabled,
  className,
  disablePastDates = false,
  showClearButton = true,
  defaultMonth,
  minDate,
  maxDate,
}: DateTimePickerProps) {
  const { t, locale } = useIntl();
  const hourRef = useRef<HTMLInputElement>(null);
  const minuteRef = useRef<HTMLInputElement>(null);

  const formatDate = (date: Date) => {
    return DateTime.fromJSDate(date).setLocale(locale).toFormat('dd.MM.yyyy - HH:mm');
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          className={cn('w-full justify-start text-left font-normal', !date && 'text-muted-foreground', className)}
          disabled={disabled}
        >
          <CalendarIcon className="mr-2 size-4 shrink-0" />
          <span className="flex-1">
            {date ? formatDate(date) : <span>{placeholder || t('common.selectDateTime')}</span>}
          </span>
          {date && showClearButton && !disabled && (
            <Button
              asChild
              type="button"
              variant="ghost"
              size="icon"
              onClick={(e) => {
                e.stopPropagation();
                setDate(undefined);
              }}
              className="ml-2 size-6 shrink-0 opacity-50 hover:opacity-100"
            >
              <X className="size-4" />
            </Button>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="single"
          selected={date}
          onSelect={(newDate) => {
            if (!newDate) {
              setDate(undefined);
              return;
            }
            // Preserve the current time when selecting a new date
            const currentDate = date || new Date();
            newDate.setHours(currentDate.getHours());
            newDate.setMinutes(currentDate.getMinutes());
            newDate.setSeconds(0);
            setDate(newDate);
          }}
          disabled={(date) => {
            // Check if date is before today if disablePastDates is true
            if (disablePastDates && date < new Date(new Date().setHours(0, 0, 0, 0))) {
              return true;
            }
            // Check if date is before 1900
            if (date < new Date('1900-01-01')) {
              return true;
            }
            // Check if date is outside min/max range
            if (minDate && date < new Date(minDate.setHours(0, 0, 0, 0))) {
              return true;
            }
            if (maxDate && date > new Date(maxDate.setHours(23, 59, 59, 999))) {
              return true;
            }
            return false;
          }}
          initialFocus
          defaultMonth={defaultMonth || date}
        />
        <div className="border-t border-border p-3">
          <div className="flex items-end gap-2">
            <div className="grid gap-1 text-center">
              <Label htmlFor="hours" className="text-xs">
                {t('common.hours')}
              </Label>
              <TimePickerInput
                picker="hours"
                date={date}
                setDate={setDate}
                ref={hourRef}
                onRightFocus={() => minuteRef.current?.focus()}
                disabled={disabled}
              />
            </div>
            <div className="grid gap-1 text-center">
              <Label htmlFor="minutes" className="text-xs">
                {t('common.minutes')}
              </Label>
              <TimePickerInput
                picker="minutes"
                date={date}
                setDate={setDate}
                ref={minuteRef}
                onLeftFocus={() => hourRef.current?.focus()}
                disabled={disabled}
              />
            </div>
            <div className="flex h-10 items-center">
              <Clock className="ml-2 size-4" />
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
