import { useOrder } from '@/core/api';
import type { useAuth } from '@/core/auth';
import { AuthLayout, JuhLayout } from '@/core/layouts';
import { CustomerLayout } from '@/core/layouts/customer-layout';
import { ManageOrganization as CustomerOrganization } from '@/features/customer/organization';
import { CategoriesOverview, ManageCategory } from '@/features/juh/categories';
import { ManageArticleMetadata, ManageMetadataGroups, ManageOrderMetadata } from '@/features/juh/metadata';
import { MetadataLayout } from '@/features/juh/metadata/layouts/metadata-layout';
import { ManageObject, ObjectsOverview } from '@/features/juh/objects';
import { ManageOrganization, OrganizationsOverview } from '@/features/juh/organizations';
import { CreateTemplate, EditTemplate, TemplateOverview } from '@/features/juh/templates';
import { MyAccount } from '@/features/shared/account';
import { ArticleOverview, CreateArticle, EditArticle } from '@/features/shared/articles';
import { AcceptInvite, Login, RequestPasswordReset, ResetPassword } from '@/features/shared/auth';
import { CertificateLoginPage } from '@/features/shared/auth/routes/certificate-login';
import { Notifications } from '@/features/shared/notifications';
import {
  ArticleRentalDetails,
  DeploymentPlanningDetails,
  OrderDetails,
  OrdersOverview,
} from '@/features/shared/orders';
import { UserManagement } from '@/features/shared/users';
import type { CustomRouteObjectUnion } from '@/shared/types';
import { redirect } from 'react-router-dom';

import { privateRoute, publicRoute, unknownRoute } from './guards';

export const createRoutes = (user: ReturnType<typeof useAuth>['user']) =>
  [
    {
      path: '*',
      loader: () => unknownRoute(user),
      element: <div>Unknown</div>,
    },
    {
      path: '/auth',
      element: <AuthLayout />,
      loader: () => publicRoute(user),
      children: [
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'certificates',
          element: <CertificateLoginPage />,
        },
        {
          path: 'forgot-password',
          element: <RequestPasswordReset />,
        },
        {
          path: 'reset-password',
          element: <ResetPassword />,
        },
        {
          path: 'accept-invite',
          element: <AcceptInvite />,
        },
      ],
    },
    {
      path: '/c',
      loader: () => privateRoute(['customer-admin', 'customer-employee'])(user),
      element: <CustomerLayout />,
      children: [
        {
          index: true,
          loader: () => redirect('/c/my-orders'),
        },
        {
          path: 'my-orders',
          handle: {
            breadcrumb: () => 'orders.myOrders',
          },
          children: [
            {
              index: true,
              element: <OrdersOverview />,
            },
            {
              path: ':id',
              element: <OrderDetails />,
              handle: {
                breadcrumb: (match) => ({
                  query: {
                    queryFn: useOrder,
                    id: match.params.id!,
                    dataKey: 'title',
                  },
                }),
              },
              children: [
                {
                  index: true,
                  loader: () => redirect('general'),
                },
                {
                  path: 'general',
                  element: <OrderDetails />,
                  handle: {
                    tab: 'general',
                  },
                },
                {
                  path: 'activity',
                  element: <OrderDetails />,
                  handle: {
                    tab: 'activity',
                  },
                },
              ],
            },
            {
              path: ':id/sub-orders/:subOrderId/article-rental',
              element: <ArticleRentalDetails />,
              handle: {
                breadcrumb: (match) => [
                  {
                    query: {
                      queryFn: useOrder,
                      id: match.params.id!,
                      dataKey: 'title',
                    },
                  },
                  match.params.subOrderId === 'new'
                    ? { label: 'orders.newSubOrder' }
                    : {
                        query: {
                          queryFn: useOrder,
                          id: match.params.subOrderId!,
                          dataKey: 'title',
                        },
                      },
                ],
              },
              children: [
                {
                  index: true,
                  loader: () => redirect('general'),
                },
                {
                  path: 'general',
                  element: <ArticleRentalDetails />,
                  handle: {
                    tab: 'general',
                  },
                },
                {
                  path: 'activity',
                  element: <ArticleRentalDetails />,
                  handle: {
                    tab: 'activity',
                  },
                },
              ],
            },
            {
              path: ':id/sub-orders/:subOrderId/deployment-planning',
              element: <DeploymentPlanningDetails />,
              handle: {
                breadcrumb: (match) => [
                  {
                    query: {
                      queryFn: useOrder,
                      id: match.params.id!,
                      dataKey: 'title',
                    },
                  },
                  match.params.subOrderId === 'new'
                    ? { label: 'orders.newSubOrder' }
                    : {
                        query: {
                          queryFn: useOrder,
                          id: match.params.subOrderId!,
                          dataKey: 'title',
                        },
                      },
                ],
              },
              children: [
                {
                  index: true,
                  loader: () => redirect('general'),
                },
                {
                  path: 'general',
                  element: <DeploymentPlanningDetails />,
                  handle: {
                    tab: 'general',
                  },
                },
                {
                  path: 'activity',
                  element: <DeploymentPlanningDetails />,
                  handle: {
                    tab: 'activity',
                  },
                },
              ],
            },
          ],
        },

        {
          path: 'my-articles',
          handle: {
            breadcrumb: () => 'stock.stock',
          },
          children: [
            {
              index: true,
              element: <ArticleOverview />,
            },
            {
              path: ':id',
              element: <EditArticle />,
              handle: {
                breadcrumb: () => 'articles.editArticle',
              },
              children: [
                {
                  index: true,
                  loader: () => redirect('details'),
                },
                {
                  path: 'details',
                  element: <EditArticle />,
                  handle: {
                    tab: 'details',
                  },
                },
                {
                  path: 'children',
                  element: <EditArticle />,
                  handle: {
                    tab: 'children',
                  },
                },
                {
                  path: 'activity',
                  element: <EditArticle />,
                  handle: {
                    tab: 'activity',
                  },
                },
              ],
            },
          ],
        },

        {
          path: 'my-organization/organization',
          element: <CustomerOrganization />,
          handle: {
            breadcrumb: () => 'organizations.organization',
          },
        },
        {
          path: 'my-organization/users',
          element: <UserManagement allowedRoles={['customer-admin', 'customer-employee']} />,
          handle: {
            breadcrumb: () => 'users.users',
          },
        },
        {
          path: 'my-account',
          element: <MyAccount />,
          handle: {
            breadcrumb: () => 'auth.myAccount',
          },
        },
        {
          path: 'notifications',
          handle: {
            breadcrumb: () => 'common.notifications',
          },
          children: [
            {
              index: true,
              loader: () => redirect('unread'),
            },
            {
              path: 'unread',
              element: <Notifications />,
            },
            {
              path: 'read',
              element: <Notifications />,
            },
            {
              path: 'archived',
              element: <Notifications />,
            },
          ],
        },
      ],
    },
    {
      path: '/juh',
      loader: () => privateRoute(['juh-admin', 'juh-employee'])(user),
      element: <JuhLayout />,
      children: [
        {
          index: true,
          loader: () => redirect('/juh/order-management/orders'),
        },

        {
          path: 'order-management',
          children: [
            {
              path: 'orders',
              handle: {
                breadcrumb: () => 'orders.orders',
              },
              children: [
                {
                  index: true,
                  element: <OrdersOverview />,
                },
                {
                  path: ':id',
                  element: <OrderDetails />,
                  handle: {
                    breadcrumb: (match) => ({
                      query: {
                        queryFn: useOrder,
                        id: match.params.id!,
                        dataKey: 'title',
                      },
                    }),
                  },
                  children: [
                    {
                      index: true,
                      loader: () => redirect('general'),
                    },
                    {
                      path: 'general',
                      element: <OrderDetails />,
                      handle: {
                        tab: 'general',
                      },
                    },
                    {
                      path: 'activity',
                      element: <OrderDetails />,
                      handle: {
                        tab: 'activity',
                      },
                    },
                  ],
                },
                {
                  path: 'new',
                  element: <OrderDetails />,
                  handle: {
                    breadcrumb: () => 'orders.newOrder',
                  },
                },
                {
                  path: ':id/sub-orders/:subOrderId/article-rental',
                  element: <ArticleRentalDetails />,
                  handle: {
                    breadcrumb: (match) => [
                      {
                        query: {
                          queryFn: useOrder,
                          id: match.params.id!,
                          dataKey: 'title',
                        },
                      },
                      match.params.subOrderId === 'new'
                        ? { label: 'orders.newSubOrder' }
                        : {
                            query: {
                              queryFn: useOrder,
                              id: match.params.subOrderId!,
                              dataKey: 'title',
                            },
                          },
                    ],
                  },
                  children: [
                    {
                      index: true,
                      loader: () => redirect('general'),
                    },
                    {
                      path: 'general',
                      element: <ArticleRentalDetails />,
                      handle: {
                        tab: 'general',
                      },
                    },
                    {
                      path: 'activity',
                      element: <ArticleRentalDetails />,
                      handle: {
                        tab: 'activity',
                      },
                    },
                  ],
                },
                {
                  path: ':id/sub-orders/:subOrderId/deployment-planning',
                  element: <DeploymentPlanningDetails />,
                  handle: {
                    breadcrumb: (match) => [
                      {
                        query: {
                          queryFn: useOrder,
                          id: match.params.id!,
                          dataKey: 'title',
                        },
                      },
                      match.params.subOrderId === 'new'
                        ? { label: 'orders.newSubOrder' }
                        : {
                            query: {
                              queryFn: useOrder,
                              id: match.params.subOrderId!,
                              dataKey: 'title',
                            },
                          },
                    ],
                  },
                  children: [
                    {
                      index: true,
                      loader: () => redirect('general'),
                    },
                    {
                      path: 'general',
                      element: <DeploymentPlanningDetails />,
                      handle: {
                        tab: 'general',
                      },
                    },
                    {
                      path: 'activity',
                      element: <DeploymentPlanningDetails />,
                      handle: {
                        tab: 'activity',
                      },
                    },
                  ],
                },
              ],
            },
            {
              path: 'objects',
              handle: {
                breadcrumb: () => 'orders.objects',
              },
              children: [
                {
                  index: true,
                  element: <ObjectsOverview />,
                },
                {
                  path: 'new',
                  element: <ManageObject />,
                  handle: {
                    breadcrumb: () => 'objects.newObject',
                  },
                },
                {
                  path: ':id',
                  element: <ManageObject />,
                  handle: {
                    breadcrumb: () => 'objects.editObject',
                  },
                },
              ],
            },
            {
              path: 'metadata',
              element: <ManageOrderMetadata />,
              handle: {
                breadcrumb: () => 'orders.metadata',
              },
            },
          ],
        },

        {
          path: 'article-management',
          children: [
            {
              path: 'articles',
              handle: {
                breadcrumb: () => 'stock.stock',
              },
              children: [
                {
                  index: true,
                  element: <ArticleOverview />,
                },
                {
                  path: ':id',
                  element: <EditArticle />,
                  handle: {
                    breadcrumb: () => 'articles.editArticle',
                  },
                  children: [
                    {
                      index: true,
                      loader: () => redirect('details'),
                    },
                    {
                      path: 'details',
                      element: <EditArticle />,
                      handle: {
                        tab: 'details',
                      },
                    },
                    {
                      path: 'children',
                      element: <EditArticle />,
                      handle: {
                        tab: 'children',
                      },
                    },
                    {
                      path: 'activity',
                      element: <EditArticle />,
                      handle: {
                        tab: 'activity',
                      },
                    },
                  ],
                },
                {
                  path: 'new',
                  element: <CreateArticle />,
                  handle: {
                    breadcrumb: () => 'articles.newArticle',
                  },
                },
              ],
            },

            {
              path: 'templates',
              handle: {
                breadcrumb: () => 'templates.templates',
              },
              children: [
                {
                  index: true,
                  element: <TemplateOverview />,
                },
                {
                  path: ':id',
                  element: <EditTemplate />,
                  handle: {
                    breadcrumb: () => 'templates.editTemplate',
                  },
                  children: [
                    {
                      index: true,
                      loader: () => redirect('details'),
                    },
                    {
                      path: 'details',
                      element: <EditTemplate />,
                    },
                  ],
                },
                {
                  path: 'new',
                  element: <CreateTemplate />,
                  handle: {
                    breadcrumb: () => 'templates.newTemplate',
                  },
                },
              ],
            },

            {
              path: 'categories',
              handle: {
                breadcrumb: () => 'categories.categories',
              },
              children: [
                {
                  index: true,
                  element: <CategoriesOverview />,
                },
                {
                  path: 'new',
                  element: <ManageCategory />,
                  handle: {
                    breadcrumb: () => 'categories.newCategory',
                  },
                },
                {
                  path: ':id',
                  element: <ManageCategory />,
                  handle: {
                    breadcrumb: () => 'categories.editCategory',
                  },
                },
              ],
            },
          ],
        },

        {
          path: 'customer-management',
          children: [
            {
              path: 'organizations',
              handle: {
                breadcrumb: () => 'organizations.organizations',
              },
              children: [
                {
                  index: true,
                  element: <OrganizationsOverview />,
                },
                {
                  path: 'new',
                  element: <ManageOrganization />,
                  handle: {
                    breadcrumb: () => 'organizations.newOrganization',
                  },
                },
                {
                  path: ':id',
                  element: <ManageOrganization />,
                  handle: {
                    breadcrumb: () => 'organizations.editOrganization',
                  },
                },
              ],
            },
          ],
        },

        {
          path: 'settings',
          children: [
            {
              path: 'users',
              element: <UserManagement allowedRoles={['juh-admin', 'juh-employee']} />,
              handle: {
                breadcrumb: () => 'users.users',
              },
            },
            {
              path: 'metadata',
              element: <MetadataLayout />,
              handle: {
                breadcrumb: () => 'metadata.metadata',
              },
              children: [
                {
                  index: true,
                  loader: () => redirect('article'),
                },
                {
                  path: 'article',
                  element: <ManageArticleMetadata />,
                },
                {
                  path: 'order',
                  element: <ManageOrderMetadata />,
                },
                {
                  path: 'groups',
                  element: <ManageMetadataGroups />,
                },
              ],
            },
          ],
        },

        {
          path: 'my-account',
          element: <MyAccount />,
          handle: {
            breadcrumb: () => 'auth.myAccount',
          },
        },
        {
          path: 'notifications',
          handle: {
            breadcrumb: () => 'common.notifications',
          },
          children: [
            {
              index: true,
              loader: () => redirect('unread'),
            },
            {
              path: 'unread',
              element: <Notifications />,
            },
            {
              path: 'read',
              element: <Notifications />,
            },
            {
              path: 'archived',
              element: <Notifications />,
            },
          ],
        },
      ],
    },
  ] as CustomRouteObjectUnion[];
