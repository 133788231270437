import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from '@/shared/components/ui';
import { useIntl } from '@tiny-intl/react';
import type { Control } from 'react-hook-form';

import type { DetailsFormValues } from '../types';

interface TemplateDetailsCardProps {
  control: Control<DetailsFormValues>;
}

export function TemplateDetailsCard({ control }: TemplateDetailsCardProps) {
  const { t } = useIntl();

  return (
    <Card className="">
      <CardHeader>
        <CardTitle>{t('templates.templateDetails')}</CardTitle>
        <CardDescription>{t('templates.templateDetailsDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <FormField
            control={control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>{t('templates.templateName')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('common.description')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </CardContent>
    </Card>
  );
}
