import { useCreateOrder, useDeleteOrder, useGlobalMetadata, useOrder, useOrders, useUpdateOrder } from '@/core/api';
import type { PayloadApiError } from '@/core/api/client';
import { useAuth } from '@/core/auth';
import { PageHeader } from '@/shared/components/layout/page-header';
import { TabNav } from '@/shared/components/layout/tab-nav';
import { Button, Separator } from '@/shared/components/ui';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/shared/components/ui';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/shared/components/ui/alert-dialog';
import { useTablePreferences } from '@/shared/services/table-preferences';
import { hasEditableMetadata } from '@/shared/utils/metadata';
import type { Order } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { PlusCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { OrderActivity } from '../components/order-activity';
import { OrderForm } from '../components/order-form';
import { OrderSubOrders } from '../components/order-sub-orders';
import { OrdersTimeline } from '../components/orders-timeline';
import { ViewSelect } from '../components/view-select';
import type { OrderFormData } from '../types/order-form';

export function OrderDetails() {
  const { t } = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { user } = useAuth();
  const isEditMode = Boolean(id);
  const { data: order } = useOrder(id!, { disabled: !isEditMode });

  const [error, setError] = useState<PayloadApiError | undefined>();
  const [orderToDelete, setOrderToDelete] = useState<Order | null>(null);

  const tablePreferences = useTablePreferences({
    tableId: 'order-sub-orders',
    defaultPreferences: {
      view: 'table',
    },
  });

  // Load initial preferences
  const preferences = tablePreferences.loadPreferences();
  const [view, setView] = useState<'table' | 'timeline'>((preferences.view as 'table' | 'timeline') || 'table');

  // Save preferences when they change
  useEffect(() => {
    tablePreferences.savePreferences({
      view,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  const isJuhUser = user?.role === 'juh-admin' || user?.role === 'juh-employee';
  const isJuhAdmin = user?.role === 'juh-admin';
  const basePath = isJuhUser ? '/juh/order-management/orders' : '/orders';

  const { data: subOrdersData, isLoading: isLoadingSubOrders } = useOrders({
    limit: 0,
    where: {
      parent: {
        equals: id,
      },
    },
  });

  const createOrder = useCreateOrder();
  const updateOrder = useUpdateOrder();
  const deleteOrder = useDeleteOrder();

  const { data: globalMetadata } = useGlobalMetadata();

  const hasEditableFields = hasEditableMetadata(globalMetadata?.rootOrderMetadata, order?.dynamicMetadataSchema);

  const currentTab = location.pathname.split('/').pop();

  const tabs = [
    { value: 'general', label: t('common.general') },
    ...(isJuhAdmin ? [{ value: 'activity', label: t('common.activity') }] : []),
  ];

  const showActions = !isEditMode || currentTab === 'general';

  const handleSubmit = async (data: OrderFormData) => {
    try {
      setError(undefined);
      if (isEditMode) {
        if (!isJuhAdmin) {
          if (isJuhUser) {
            return;
          }
          await updateOrder.mutateAsync({
            id: order!.id,
            data: {
              metadata: data.metadata,
              dynamicMetadataValues: data.dynamicMetadataValues,
            },
          });
        } else {
          await updateOrder.mutateAsync({ id: order!.id, data });
        }
      } else {
        if (!isJuhAdmin) {
          return;
        }
        const response = await createOrder.mutateAsync({
          ...data,
          type: 'root-order',
        });
        navigate(`${basePath}/${response.doc.id}`);
      }
      toast.success(t('common.savedSuccessfully'));
    } catch (err) {
      setError(err as PayloadApiError);
      toast.error(t('common.errorSaving'));
    }
  };

  const handleDeleteOrder = async () => {
    if (orderToDelete) {
      try {
        await deleteOrder.mutateAsync(orderToDelete.id);
        toast.success(t('orders.orderDeletedSuccessfully'));
        setOrderToDelete(null);
      } catch (error) {
        toast.error(t('orders.errorDeletingOrder'));
      }
    }
  };

  if (isEditMode && !order) {
    return null;
  }

  return (
    <>
      <PageHeader
        title={isJuhUser ? t(isEditMode ? 'orders.editOrder' : 'orders.newOrder') : t('orders.orderDetails')}
        backHref={basePath}
        primaryAction={
          showActions
            ? isJuhUser
              ? {
                  label: isEditMode ? t('common.save') : t('common.create'),
                  loadingLabel: isEditMode ? t('common.saving') : t('common.creating'),
                  form: 'order-form',
                  type: 'submit',
                  disabled: (isEditMode ? updateOrder.isPending : createOrder.isPending) || !isJuhAdmin,
                  isLoading: isEditMode ? updateOrder.isPending : createOrder.isPending,
                }
              : hasEditableFields
                ? {
                    label: t('common.save'),
                    loadingLabel: t('common.saving'),
                    form: 'order-form',
                    type: 'submit',
                    disabled: updateOrder.isPending,
                    isLoading: updateOrder.isPending,
                  }
                : undefined
            : undefined
        }
        secondaryAction={
          showActions && isJuhUser
            ? {
                label: t('common.discard'),
                onClick: () => navigate(basePath),
                variant: 'outline',
              }
            : undefined
        }
      />

      {isEditMode && <TabNav tabs={tabs} basePath={`${basePath}/${id}`} />}

      <div className="grid gap-6">
        {(!isEditMode || currentTab === 'general') && (
          <OrderForm id="order-form" defaultValues={order} onSubmit={handleSubmit} error={error} />
        )}

        {isEditMode && currentTab === 'activity' && <OrderActivity orderId={id} />}

        {isEditMode && currentTab === 'general' && (
          <>
            <Separator />
            <div className="space-y-4 overflow-hidden">
              <div className="flex items-center justify-between">
                <div>
                  <h2 className="text-lg font-semibold">{t('orders.subOrders')}</h2>
                  <p className="text-sm text-muted-foreground">{t('orders.subOrdersDescription')}</p>
                </div>
                <div className="flex items-center gap-4">
                  <ViewSelect view={view} onViewChange={(value) => setView(value)} />
                  {isJuhUser && (
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button disabled={!isJuhAdmin}>
                          <PlusCircle className="size-4 mr-2" />
                          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">{t('orders.addOrder')}</span>
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuLabel>{t('orders.chooseOrderType')}</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem
                          disabled={!isJuhAdmin}
                          onClick={() => navigate(`${basePath}/${id}/sub-orders/new/article-rental`)}
                        >
                          {t('orders.article-rental')}
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          disabled={!isJuhAdmin}
                          onClick={() => navigate(`${basePath}/${id}/sub-orders/new/deployment-planning`)}
                        >
                          {t('orders.deployment-planning')}
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  )}
                </div>
              </div>

              {view === 'table' ? (
                <OrderSubOrders
                  orders={subOrdersData?.docs ?? []}
                  isLoading={isLoadingSubOrders}
                  onDelete={isJuhUser ? setOrderToDelete : undefined}
                />
              ) : isLoadingSubOrders ? (
                <div className="text-center">{t('common.loading')}</div>
              ) : subOrdersData?.docs && subOrdersData.docs.length > 0 ? (
                <OrdersTimeline orders={subOrdersData.docs} onDelete={isJuhUser ? setOrderToDelete : undefined} />
              ) : (
                <div className="text-center">{t('common.noResults')}</div>
              )}
            </div>
          </>
        )}
      </div>

      <AlertDialog open={!!orderToDelete} onOpenChange={(open) => !open && setOrderToDelete(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('orders.deleteOrder')}</AlertDialogTitle>
            <AlertDialogDescription>
              {t('orders.deleteOrderConfirmation', { name: orderToDelete?.orderNumber || '' })}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
            <AlertDialogAction onClick={handleDeleteOrder}>{t('common.delete')}</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
