import { jsonSchemaType } from '@/shared/schema';
import { z } from 'zod';

export const createObjectSchema = (
  t: (key: string) => string,
  dynamicMetadataSchema: z.ZodObject<z.ZodRawShape, z.UnknownKeysParam, z.ZodTypeAny>,
) =>
  z.object({
    name: z.string().min(1, t('validation.required')),
    description: z.string().optional(),
    dynamicMetadataSchema: jsonSchemaType,
    dynamicMetadataValues: dynamicMetadataSchema,
  });

// Default schema for cases where we don't need validation
export const objectFormSchema = createObjectSchema(() => 'Required', z.object({}).catchall(z.unknown()));
