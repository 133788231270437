import { useArticle, useArticles, useDeleteArticle, useOrder, useTemplate } from '@/core/api';
import { useAuth } from '@/core/auth';
import { SearchCombobox, SearchField } from '@/shared/components/inputs';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  EmptyTableRow,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/shared/components/ui';
import { useDebounce } from '@/shared/hooks/use-debounce';
import { useTablePreferences } from '@/shared/services/table-preferences';
import type { Article } from '@johanniter-offshore/backend';
import type { ColumnDef, ColumnFiltersState, Row, SortingState, VisibilityState } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useIntl } from '@tiny-intl/react';
import { ArrowRight, Filter, LayoutTemplate, MoreHorizontal, PlusCircle } from 'lucide-react';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import { DataTablePagination } from './data-table-navigation';
import { TableLoadingCell } from './table-loading-cell';

const TemplateCell = ({ templateId }: { templateId: string }) => {
  const { data: template, isLoading } = useTemplate(templateId);
  if (isLoading) return <Skeleton className="h-4 w-24" />;
  return <div>{template?.name || '-'}</div>;
};

const OrderCell = ({ orderId }: { orderId: string | null }) => {
  const { data: order, isLoading } = useOrder(orderId || '', { disabled: !orderId });
  if (!orderId) return <div>-</div>;
  if (isLoading) return <Skeleton className="h-4 w-24" />;
  return <div>{order?.orderNumber || '-'}</div>;
};

const ParentCell = ({ parentId }: { parentId: string | null }) => {
  const { data: parent, isLoading } = useArticle(parentId || '', { disabled: !parentId });
  const template = parent?.template as string;
  const { data: templateData } = useTemplate(template || '', { disabled: !template });
  const { user } = useAuth();
  const isJuhUser = user?.role === 'juh-admin' || user?.role === 'juh-employee';
  const basePath = isJuhUser ? '/juh/article-management/articles' : '/c/my-articles';

  if (!parentId) return <div>-</div>;
  if (isLoading) return <Skeleton className="h-4 w-24" />;

  return (
    <Link to={`${basePath}/${parentId}`} className="hover:underline">
      {templateData?.name || '-'}
    </Link>
  );
};

const ActionCell = ({
  article,
  onDelete,
  readOnly,
}: {
  article: Article;
  onDelete: (article: Article) => void;
  readOnly?: boolean;
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useIntl();
  const isJuhAdmin = user?.role === 'juh-admin';
  const isJuhUser = user?.role === 'juh-admin' || user?.role === 'juh-employee';
  const basePath = isJuhUser ? '/juh/article-management/articles' : '/c/my-articles';
  const hasParent = Boolean(article.parent);

  if (readOnly) {
    return (
      <Button
        variant="ghost"
        size="icon"
        onClick={() => navigate(`${basePath}/${article.id}`)}
        aria-label={t('common.view')}
      >
        <ArrowRight className="size-4" />
      </Button>
    );
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="size-8 p-0">
          <span className="sr-only">{t('navigation.openMenu')}</span>
          <MoreHorizontal className="size-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t('common.actions')}</DropdownMenuLabel>
        <DropdownMenuItem onClick={() => navigate(`${basePath}/${article.id}`)}>{t('common.edit')}</DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate(`${basePath}/new?copy=${article.id}`)}>
          {t('articles.copyArticle')}
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => onDelete(article)}
          disabled={hasParent || !isJuhAdmin}
          title={
            hasParent
              ? t('articles.cannotDeleteChildArticle')
              : !isJuhAdmin
                ? t('articles.onlyJuhAdminCanDelete')
                : undefined
          }
        >
          {t('common.delete')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

interface ArticleTableProps {
  where?: Record<string, unknown>;
  hideFilters?: boolean;
  hideActions?: boolean;
  hideOrder?: boolean;
  customActions?: React.ReactNode;
  customCellActions?: (article: Article) => React.ReactNode;
  readOnly?: boolean;
  /** Unique identifier for storing table preferences */
  tableId: string;
}

export function ArticleTable({
  where = {},
  hideFilters = false,
  hideActions = false,
  hideOrder = false,
  customActions,
  customCellActions,
  readOnly = false,
  tableId,
}: ArticleTableProps) {
  const { t } = useIntl();
  const navigate = useNavigate();
  const { user } = useAuth();

  const tablePreferences = useTablePreferences({
    tableId,
    defaultPreferences: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
      sorting: [],
      columnFilters: [],
      columnVisibility: {},
      searchTerm: '',
      selectedFilters: {
        activeTab: 'all',
        templateId: undefined,
      },
    },
  });

  // Load initial preferences
  const preferences = tablePreferences.loadPreferences();

  const [searchTerm, setSearchTerm] = useState(preferences.searchTerm || '');
  const [activeTab, setActiveTab] = useState<'all' | 'container' | 'single'>(
    (preferences.selectedFilters?.activeTab as 'all' | 'container' | 'single') || 'all',
  );
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | undefined>(
    preferences.selectedFilters?.templateId as string | undefined,
  );
  const [sorting, setSorting] = useState<SortingState>(preferences.sorting || []);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(preferences.columnFilters || []);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(preferences.columnVisibility || {});
  const [pagination, setPagination] = useState(
    preferences.pagination || {
      pageIndex: 0,
      pageSize: 10,
    },
  );

  // Save preferences when they change
  useEffect(() => {
    tablePreferences.savePreferences({
      pagination,
      sorting,
      columnFilters,
      columnVisibility,
      searchTerm,
      selectedFilters: {
        activeTab,
        templateId: selectedTemplateId,
      },
    });
  }, [
    tablePreferences,
    pagination,
    sorting,
    columnFilters,
    columnVisibility,
    searchTerm,
    activeTab,
    selectedTemplateId,
  ]);

  const handleSearchChange = useCallback((value: string) => {
    setSearchTerm(value);
    setPagination((prev: { pageIndex: number; pageSize: number }) => ({ ...prev, pageIndex: 0 }));
  }, []);

  const handleActiveTabChange = (value: 'all' | 'container' | 'single') => {
    setActiveTab(value);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  };

  const handleTemplateChange = (value: string | null | undefined) => {
    setSelectedTemplateId(value === null ? undefined : value);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  };

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const isJuhUser = user?.role === 'juh-admin' || user?.role === 'juh-employee';
  const basePath = isJuhUser ? '/juh/article-management/articles' : '/c/my-articles';

  const columns: ColumnDef<Article>[] = [
    {
      accessorKey: 'template',
      header: t('templates.template'),
      cell: ({ row }: { row: Row<Article> }) => <TemplateCell templateId={row.getValue('template') as string} />,
    },
    {
      accessorKey: 'expiryDate',
      header: t('articles.expiryDate'),
      cell: ({ row }: { row: Row<Article> }) => {
        const expiryDate = row.getValue('expiryDate') as string | null;
        if (!expiryDate) return <div>-</div>;
        const date = DateTime.fromISO(expiryDate);
        return <div>{date.toFormat('dd.MM.yyyy')}</div>;
      },
    },
    !hideOrder && {
      accessorKey: 'order',
      header: t('orders.order'),
      cell: ({ row }: { row: Row<Article> }) => <OrderCell orderId={row.getValue('order') as string} />,
    },
    {
      accessorKey: 'parent',
      header: t('contents.parentArticle'),
      cell: ({ row }: { row: Row<Article> }) => <ParentCell parentId={row.getValue('parent') as string} />,
    },
    !hideActions && {
      id: 'actions',
      cell: ({ row }: { row: Row<Article> }) =>
        customCellActions ? (
          customCellActions(row.original)
        ) : (
          <ActionCell article={row.original} onDelete={setArticleToDelete} readOnly={readOnly} />
        ),
    },
  ].filter(Boolean) as ColumnDef<Article>[];

  const [rowSelection, setRowSelection] = useState({});

  const { data: articlesData, isLoading } = useArticles({
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    where: {
      ...where,
      ...(activeTab === 'all'
        ? {}
        : {
            'template.isContainer': {
              equals: activeTab === 'container',
            },
          }),
      ...(selectedTemplateId
        ? {
            template: {
              equals: selectedTemplateId,
            },
          }
        : {}),
      ...(debouncedSearchTerm
        ? {
            or: [
              { 'template.name': { contains: debouncedSearchTerm } },
              { 'template.templateCategory.name': { contains: debouncedSearchTerm } },
              { 'order.orderNumber': { contains: debouncedSearchTerm } },
            ],
          }
        : {}),
    },
  });

  const articles = articlesData?.docs || [];
  const totalPages = articlesData?.totalPages || 0;

  const table = useReactTable({
    data: articles,
    columns,
    pageCount: totalPages,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination,
    },
    onPaginationChange: (updaterOrValue) => {
      const newValue = typeof updaterOrValue === 'function' ? updaterOrValue(pagination) : updaterOrValue;
      setPagination(newValue);
    },
    manualPagination: true,
    onSortingChange: (updaterOrValue) => {
      const newValue = typeof updaterOrValue === 'function' ? updaterOrValue(sorting) : updaterOrValue;
      setSorting(newValue);
    },
    onColumnFiltersChange: (updaterOrValue) => {
      const newValue = typeof updaterOrValue === 'function' ? updaterOrValue(columnFilters) : updaterOrValue;
      setColumnFilters(newValue);
    },
    onColumnVisibilityChange: (updaterOrValue) => {
      const newValue = typeof updaterOrValue === 'function' ? updaterOrValue(columnVisibility) : updaterOrValue;
      setColumnVisibility(newValue);
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const deleteArticle = useDeleteArticle();
  const [articleToDelete, setArticleToDelete] = useState<Article | null>(null);

  const confirmDeleteArticle = async () => {
    if (articleToDelete) {
      try {
        await deleteArticle.mutateAsync(articleToDelete.id);
        toast.success(t('articles.articleDeletedSuccessfully'));
        setArticleToDelete(null);
      } catch (error) {
        toast.error(t('articles.errorDeletingArticle'));
      }
    }
  };

  return (
    <>
      {!hideFilters && (
        <div className="flex items-center">
          <SearchField searchTerm={searchTerm} setSearchTerm={handleSearchChange} />
          <div className="ml-auto flex items-center gap-4">
            <Select value={activeTab} onValueChange={handleActiveTabChange}>
              <SelectTrigger className="min-w-[180px] w-auto">
                <div className="flex items-center">
                  <Filter className="size-4 mr-2 text-muted-foreground" />
                  <SelectValue placeholder={t('articles.allArticles')} />
                </div>
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">{t('articles.allArticles')}</SelectItem>
                <SelectItem value="container">{t('articles.containerArticles')}</SelectItem>
                <SelectItem value="single">{t('articles.singleArticles')}</SelectItem>
              </SelectContent>
            </Select>
            <SearchCombobox
              value={selectedTemplateId}
              onSelect={handleTemplateChange}
              collectionKey="templates"
              searchKey="name"
              className="h-9 min-w-[240px] w-auto"
              texts={{ selectItemMsg: `${t('templates.template')}` }}
              icon={LayoutTemplate}
            />
            {!readOnly &&
              (customActions || (
                <Button onClick={() => navigate(`${basePath}/new`)}>
                  <PlusCircle className="size-4 mr-2" />
                  <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">{t('articles.addArticle')}</span>
                </Button>
              ))}
          </div>
        </div>
      )}

      <div className="space-y-4">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                  ))}
                </TableRow>
              ))
            ) : !isLoading ? (
              <EmptyTableRow colSpan={columns.length} />
            ) : (
              <TableLoadingCell colSpan={columns.length} />
            )}
          </TableBody>
        </Table>

        <DataTablePagination table={table} />
      </div>

      {!hideActions && (
        <AlertDialog open={!!articleToDelete} onOpenChange={(open) => !open && setArticleToDelete(null)}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>{t('articles.deleteArticle')}</AlertDialogTitle>
              <AlertDialogDescription>{t('articles.deleteArticleConfirmation')}</AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
              <AlertDialogAction onClick={confirmDeleteArticle}>{t('common.delete')}</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </>
  );
}
