import { payloadClient } from '@/core/api';
import type { Where } from '@/core/api';
import type { Order, Version } from '@johanniter-offshore/backend';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

// Query keys for better type safety and reusability
export const orderKeys = {
  all: ['orders'] as const,
  lists: () => [...orderKeys.all, 'list'] as const,
  list: (params: OrderQueryParams) => [...orderKeys.lists(), params] as const,
  details: () => [...orderKeys.all, 'detail'] as const,
  detail: (id: string) => [...orderKeys.details(), id] as const,
  versions: () => [...orderKeys.all, 'versions'] as const,
  version: (id: string) => [...orderKeys.versions(), id] as const,
} as const;

// Types for better type safety
export interface OrderQueryParams {
  page?: number;
  limit?: number;
  where?: Where;
  sort?: keyof Order | `-${keyof Order}`;
  disabled?: boolean;
}

export interface OrderUpdateParams {
  id: string;
  data: Partial<Order>;
}

// Hooks with improved TypeScript types
export const useOrders = (params?: OrderQueryParams) => {
  return useQuery({
    queryKey: orderKeys.list(params ?? {}),
    enabled: !params?.disabled,
    placeholderData: (previousData) => previousData,
    queryFn: async () => {
      const response = await payloadClient.find({
        collection: 'orders',
        ...params,
      });
      return response;
    },
  });
};

export const useOrder = (id: string, params?: { disabled?: boolean }) => {
  return useQuery({
    queryKey: orderKeys.detail(id),
    enabled: !params?.disabled,
    queryFn: async () => {
      const response = await payloadClient.findById({
        collection: 'orders',
        id,
      });
      return response;
    },
  });
};

export const useOrderVersions = (id: string, params?: { disabled?: boolean; limit?: number; page?: number }) => {
  interface VersionResponse {
    docs: Version<Order>[];
    hasNextPage: boolean;
    nextPage: number | null;
  }

  return useInfiniteQuery<VersionResponse>({
    queryKey: orderKeys.version(id),
    enabled: !params?.disabled,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage,
    queryFn: async ({ pageParam = 1 }) => {
      const response = await payloadClient.customRequest<VersionResponse>({
        subpath: `/orders/versions?where[parent][equals]=${id}&sort=-createdAt&limit=${params?.limit || 15}&page=${pageParam}`,
        method: 'GET',
      });
      return response;
    },
  });
};

export const useCreateOrder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Partial<Order>) => {
      const response = await payloadClient.create({
        collection: 'orders',
        data,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: orderKeys.all });
    },
  });
};

export const useUpdateOrder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }: OrderUpdateParams) => {
      const response = await payloadClient.updateById({
        collection: 'orders',
        id,
        data,
      });
      return response;
    },
    onSuccess: (_, { id }) => {
      // Invalidate all orders queries
      queryClient.invalidateQueries({ queryKey: orderKeys.all });
      // Invalidate the specific order query
      queryClient.invalidateQueries({ queryKey: orderKeys.detail(id) });
    },
  });
};

export const useDeleteOrder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      const response = await payloadClient.deleteById({
        collection: 'orders',
        id,
      });
      return response;
    },
    onSuccess: (_, id) => {
      queryClient.invalidateQueries({ queryKey: orderKeys.all });
      // Invalidate the specific order query
      queryClient.invalidateQueries({ queryKey: orderKeys.detail(id) });
    },
  });
};
