import { useGlobalMetadata } from '@/core/api';
import type { PayloadApiError } from '@/core/api/client';
import { useAuth } from '@/core/auth';
import { SearchCombobox } from '@/shared/components/inputs';
import { MetadataAutoForm } from '@/shared/components/metadata/metadata-auto-form';
import {
  MetadataBuilder,
  type MetadataFormField,
  handleMetadataBuilderSubmitGeneric,
  jsonSchemaToFormFields,
  parseMetadata,
  preserveMetadataGroups,
} from '@/shared/components/metadata/metadata-builder';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  DatePicker,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  ScrollArea,
  Textarea,
} from '@/shared/components/ui';
import { createMetadataZodSchema } from '@/shared/utils/metadata';
import { zodResolver } from '@hookform/resolvers/zod';
import type { JsonSchemaType, Order } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { Building2, Edit3, Ship } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { createOrderSchema } from '../schema/order-schema';
import type { OrderFormData, OrderType } from '../types/order-form';

interface OrderFormProps {
  id?: string;
  defaultValues?: Partial<Order>;
  onSubmit: (data: OrderFormData) => Promise<void>;
  error?: PayloadApiError;
  orderType?: OrderType;
  parentStartDate?: string;
  parentEndDate?: string;
}

export function OrderForm({
  id,
  defaultValues,
  onSubmit,
  error,
  orderType = 'root-order',
  parentStartDate,
  parentEndDate,
}: OrderFormProps) {
  const { t } = useIntl();
  const { user } = useAuth();
  const isJuhUser = user?.role === 'juh-admin' || user?.role === 'juh-employee';
  const isJuhAdmin = user?.role === 'juh-admin';
  const isCustomer = !isJuhUser;
  const readOnly = isCustomer || !isJuhAdmin;
  const { data: globalMetadata } = useGlobalMetadata();
  const [metadataSchema, setMetadataSchema] = useState<z.ZodObject<z.ZodRawShape, z.UnknownKeysParam, z.ZodTypeAny>>(
    z.object({}),
  );
  const [isMetadataDrawerOpen, setIsMetadataDrawerOpen] = useState(false);
  const [dynamicMetadataSchema, setDynamicMetadataSchema] = useState<
    z.ZodObject<z.ZodRawShape, z.UnknownKeysParam, z.ZodTypeAny>
  >(z.object({}));

  const form = useForm<OrderFormData>({
    resolver: zodResolver(createOrderSchema(t, metadataSchema, dynamicMetadataSchema)),
    defaultValues: {
      orderNumber: defaultValues?.orderNumber || '',
      title: defaultValues?.title || '',
      organization: (defaultValues?.organization as string) || '',
      object: (defaultValues?.object as string) || null,
      startDate: defaultValues?.startDate || '',
      endDate: defaultValues?.endDate || '',
      description: defaultValues?.description || '',
      metadata: parseMetadata(defaultValues?.metadata as Record<string, unknown>),
      dynamicMetadataSchema: defaultValues?.dynamicMetadataSchema || undefined,
      dynamicMetadataValues: parseMetadata(defaultValues?.dynamicMetadataValues as Record<string, unknown>),
    },
  });

  // Update form values when defaultValues changes (e.g. when React Query refetches)
  useEffect(() => {
    // Only reset form if we're not in an error state and the values have actually changed
    if (defaultValues && !error && !form.formState.isDirty) {
      form.reset({
        orderNumber: defaultValues.orderNumber || '',
        title: defaultValues.title || '',
        organization: (defaultValues.organization as string) || '',
        object: (defaultValues.object as string) || null,
        startDate: defaultValues.startDate || '',
        endDate: defaultValues.endDate || '',
        description: defaultValues.description || '',
        metadata: parseMetadata(defaultValues.metadata as Record<string, unknown>),
        dynamicMetadataSchema: defaultValues.dynamicMetadataSchema || undefined,
        dynamicMetadataValues: parseMetadata(defaultValues.dynamicMetadataValues as Record<string, unknown>),
      });
    }
  }, [defaultValues, form, error]);

  useEffect(() => {
    if (globalMetadata) {
      let metadataConfig;
      switch (orderType) {
        case 'root-order':
          metadataConfig = globalMetadata.rootOrderMetadata;
          break;
        case 'article-rental':
          metadataConfig = globalMetadata.articleRentalOrderMetadata;
          break;
        case 'deployment-planning':
          metadataConfig = globalMetadata.deploymentPlanningMetadata;
          break;
      }

      if (metadataConfig) {
        setMetadataSchema(createMetadataZodSchema(metadataConfig, isJuhUser));
      }
    }
  }, [globalMetadata, orderType, isJuhUser]);

  useEffect(() => {
    if (defaultValues?.dynamicMetadataSchema) {
      setDynamicMetadataSchema(createMetadataZodSchema(defaultValues.dynamicMetadataSchema, isJuhUser));
    }
  }, [defaultValues?.dynamicMetadataSchema, isJuhUser]);

  const metadataBuilderForm = useForm<{ metadata: MetadataFormField[] }>({
    defaultValues: {
      metadata: defaultValues?.dynamicMetadataSchema
        ? jsonSchemaToFormFields(defaultValues.dynamicMetadataSchema)
        : [
            {
              key: `tempkey-${Date.now()}`,
              label: '',
              type: 'string',
              required: false,
              customerAccess: 'read',
              group: null,
            },
          ],
    },
  });

  const handleMetadataBuilderSubmit = async (data: { metadata: MetadataFormField[] }) => {
    if (!form.getValues().dynamicMetadataSchema) {
      form.setValue('dynamicMetadataSchema', {
        type: 'object',
        properties: {},
        required: [],
        propertyOrder: [],
      });
    }
    handleMetadataBuilderSubmitGeneric(data, form, metadataBuilderForm, setDynamicMetadataSchema, () =>
      setIsMetadataDrawerOpen(false),
    );
  };

  // Handle backend validation errors
  useEffect(() => {
    if (error?.response?.errors) {
      // Keep the current form values by resetting with them
      const currentValues = form.getValues();
      form.reset(currentValues, { keepValues: true });

      (error.response.errors as Array<{ message: string }>).forEach((err) => {
        // Date validation errors
        if (err.message === 'StartDateAfterEndDate') {
          form.setError('startDate', { message: t('validation.StartDateAfterEndDate') });
        }
        if (err.message === 'EndDateBeforeStartDate') {
          form.setError('endDate', { message: t('validation.EndDateBeforeStartDate') });
        }

        // Parent order date validation errors
        if (err.message === 'OrderStartBeforeParentStart') {
          form.setError('startDate', { message: t('validation.OrderStartBeforeParentStart') });
        }
        if (err.message === 'OrderStartAfterParentEnd') {
          form.setError('startDate', { message: t('validation.OrderStartAfterParentEnd') });
        }
        if (err.message === 'OrderEndBeforeParentStart') {
          form.setError('endDate', { message: t('validation.OrderEndBeforeParentStart') });
        }
        if (err.message === 'OrderEndAfterParentEnd') {
          form.setError('endDate', { message: t('validation.OrderEndAfterParentEnd') });
        }

        // Child order date validation errors
        if (err.message === 'ChildOrderStartBeforeParentStart') {
          form.setError('startDate', { message: t('validation.ChildOrderStartBeforeParentStart') });
        }
        if (err.message === 'ChildOrderEndAfterParentEnd') {
          form.setError('endDate', { message: t('validation.ChildOrderEndAfterParentEnd') });
        }

        // Deployment date validation errors
        if (err.message === 'DeploymentStartBeforeOrderStart') {
          form.setError('startDate', { message: t('validation.DeploymentStartBeforeOrderStart') });
        }
        if (err.message === 'DeploymentEndAfterOrderEnd') {
          form.setError('endDate', { message: t('validation.DeploymentEndAfterOrderEnd') });
        }

        // Additional deployment validation errors with order-specific messages
        if (err.message === 'DeploymentStartBeforeParent') {
          form.setError('startDate', { message: t('validation.OrderDeploymentStartBeforeParent') });
        }
        if (err.message === 'DeploymentEndAfterParent') {
          form.setError('endDate', { message: t('validation.OrderDeploymentEndAfterParent') });
        }
        if (err.message === 'DeploymentStartBeforeOrder') {
          form.setError('startDate', { message: t('validation.OrderDeploymentStartBeforeOrder') });
        }
        if (err.message === 'DeploymentEndAfterOrder') {
          form.setError('endDate', { message: t('validation.OrderDeploymentEndAfterOrder') });
        }
        if (err.message === 'DeploymentsOverlap') {
          form.setError('startDate', { message: t('validation.OrderDeploymentsOverlap') });
          form.setError('endDate', { message: t('validation.OrderDeploymentsOverlap') });
        }
        if (err.message === 'InvalidDeploymentDate') {
          form.setError('startDate', { message: t('validation.OrderInvalidDeploymentDate') });
          form.setError('endDate', { message: t('validation.OrderInvalidDeploymentDate') });
        }
      });
    }
  }, [error, form, t]);

  const handleSubmit = async (data: OrderFormData) => {
    // If we have dynamic metadata fields, ensure we preserve the group information
    if (data.dynamicMetadataSchema) {
      data.dynamicMetadataSchema = preserveMetadataGroups(
        data.dynamicMetadataSchema,
        metadataBuilderForm.getValues().metadata,
      );
    }

    await onSubmit(data);
  };

  return (
    <>
      <Form {...form}>
        <form id={id} onSubmit={form.handleSubmit(handleSubmit)}>
          <div className="grid gap-6">
            <div className="grid grid-cols-12 gap-6">
              {/* Main Details Card */}
              <div className="col-span-9 space-y-6">
                <Card>
                  <CardHeader>
                    <CardTitle>{t('orders.orderDetails')}</CardTitle>
                    <CardDescription>{t('orders.orderDetailsDescription')}</CardDescription>
                  </CardHeader>
                  <CardContent className="space-y-6">
                    <div className="grid grid-cols-2 gap-6">
                      <FormField
                        control={form.control}
                        name="title"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel required>{t('orders.title')}</FormLabel>
                            <FormControl>
                              <Input {...field} disabled={readOnly} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="orderNumber"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>{t('orders.orderNumber')}</FormLabel>
                            <FormControl>
                              <Input {...field} disabled={readOnly} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>

                    <FormField
                      control={form.control}
                      name="description"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t('orders.description')}</FormLabel>
                          <FormControl>
                            <Textarea {...field} disabled={readOnly} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </CardContent>
                </Card>

                {/* Metadata Card */}
                <Card>
                  <CardHeader className="flex flex-row items-center justify-between space-y-0">
                    <div className="space-y-1">
                      <CardTitle>{t('metadata.metadata')}</CardTitle>
                      <CardDescription>{t('orders.metadataCardDescription')}</CardDescription>
                    </div>
                    {!readOnly && isJuhAdmin && (
                      <Button
                        variant="outline"
                        size="sm"
                        className="ml-auto"
                        onClick={() => setIsMetadataDrawerOpen(true)}
                        type="button"
                      >
                        <Edit3 className="!size-3" />
                        {t('metadata.addCustomMetadata')}
                      </Button>
                    )}
                  </CardHeader>
                  <CardContent className="space-y-6">
                    {Object.keys(metadataSchema.shape || {}).length > 0 ? (
                      <MetadataAutoForm
                        schema={metadataSchema}
                        form={form}
                        path={['metadata']}
                        metadataSchema={
                          globalMetadata?.[
                            orderType === 'root-order'
                              ? 'rootOrderMetadata'
                              : orderType === 'article-rental'
                                ? 'articleRentalOrderMetadata'
                                : 'deploymentPlanningMetadata'
                          ]
                        }
                        isCustomer={isCustomer}
                        disabled={user?.role === 'juh-employee'}
                      />
                    ) : (
                      <div className="text-sm text-muted-foreground">({t('metadata.noOrderMetadata')})</div>
                    )}

                    {form.watch('dynamicMetadataSchema') &&
                      Object.keys(form.watch('dynamicMetadataSchema.properties') || {}).length > 0 && (
                        <div>
                          <h4 className="mb-4 text-sm font-medium">{t('metadata.customMetadata')}</h4>
                          <MetadataAutoForm
                            schema={dynamicMetadataSchema}
                            form={form}
                            path={['dynamicMetadataValues']}
                            metadataSchema={form.watch('dynamicMetadataSchema') as JsonSchemaType}
                            isCustomer={isCustomer}
                            disabled={user?.role === 'juh-employee'}
                          />
                        </div>
                      )}
                  </CardContent>
                </Card>
              </div>

              {/* Right Column */}
              <div className="col-span-3 space-y-6">
                {/* Organization Card */}
                <Card>
                  <CardHeader>
                    <CardTitle>{t('orders.organizationAssignment')}</CardTitle>
                    <CardDescription>{t('orders.organizationAssignmentDescription')}</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <FormLabel required>{t('organization.organization')}</FormLabel>
                    <FormField
                      control={form.control}
                      name="organization"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <SearchCombobox
                              value={field.value || undefined}
                              onSelect={field.onChange}
                              collectionKey="organizations"
                              searchKey="name"
                              disabled={
                                readOnly || orderType === 'article-rental' || orderType === 'deployment-planning'
                              }
                              texts={{
                                selectItemMsg: t('orders.selectOrganization'),
                                searchPlaceholder: t('orders.searchOrganization'),
                              }}
                              icon={Building2}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </CardContent>
                </Card>

                {/* Object Assignment Card */}
                <Card>
                  <CardHeader>
                    <CardTitle>{t('orders.objectAssignment')}</CardTitle>
                    <CardDescription>{t('orders.objectAssignmentDescription')}</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <FormLabel>{t('objects.objects')}</FormLabel>
                    <FormField
                      control={form.control}
                      name="object"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <SearchCombobox
                              value={field.value || undefined}
                              onSelect={field.onChange}
                              collectionKey="objects"
                              searchKey="name"
                              disabled={
                                readOnly || orderType === 'article-rental' || orderType === 'deployment-planning'
                              }
                              texts={{
                                selectItemMsg: t('orders.selectObject'),
                                searchPlaceholder: t('orders.searchObject'),
                              }}
                              icon={Ship}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </CardContent>
                </Card>

                {/* Dates Card */}
                <Card>
                  <CardHeader>
                    <CardTitle>{t('orders.orderDates')}</CardTitle>
                    <CardDescription>{t('orders.orderDatesDescription')}</CardDescription>
                  </CardHeader>
                  <CardContent className="space-y-6">
                    <FormField
                      control={form.control}
                      name="startDate"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel required>{t('orders.startDate')}</FormLabel>
                          <FormControl>
                            <DatePicker
                              date={field.value ? new Date(field.value) : undefined}
                              setDate={(date) => field.onChange(date?.toISOString() || '')}
                              placeholder={t('orders.chooseStartDate')}
                              defaultMonth={
                                field.value
                                  ? new Date(field.value)
                                  : parentStartDate
                                    ? new Date(parentStartDate)
                                    : undefined
                              }
                              disabled={readOnly}
                              minDate={
                                orderType !== 'root-order' && parentStartDate ? new Date(parentStartDate) : undefined
                              }
                              maxDate={
                                orderType !== 'root-order' && parentEndDate ? new Date(parentEndDate) : undefined
                              }
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="endDate"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel required>{t('orders.endDate')}</FormLabel>
                          <FormControl>
                            <DatePicker
                              date={field.value ? new Date(field.value) : undefined}
                              setDate={(date) => field.onChange(date?.toISOString() || '')}
                              placeholder={t('orders.chooseEndDate')}
                              defaultMonth={
                                field.value
                                  ? new Date(field.value)
                                  : parentStartDate
                                    ? new Date(parentStartDate)
                                    : undefined
                              }
                              disabled={readOnly}
                              minDate={
                                orderType !== 'root-order' && parentStartDate ? new Date(parentStartDate) : undefined
                              }
                              maxDate={
                                orderType !== 'root-order' && parentEndDate ? new Date(parentEndDate) : undefined
                              }
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </form>
      </Form>

      <Drawer open={isMetadataDrawerOpen} onOpenChange={setIsMetadataDrawerOpen} direction="right">
        <DrawerContent className="left-auto right-0 top-0 mt-0 h-screen w-full rounded-none max-w-[1000px]">
          <ScrollArea className="h-screen">
            <DrawerHeader>
              <DrawerTitle>{t('metadata.customMetadataBuilder')}</DrawerTitle>
              <DrawerDescription>{t('metadata.customMetadataBuilderDescription')}</DrawerDescription>
            </DrawerHeader>
            <Form {...metadataBuilderForm}>
              <form onSubmit={metadataBuilderForm.handleSubmit(handleMetadataBuilderSubmit)}>
                <div className="space-y-4 p-4">
                  <MetadataBuilder />
                </div>
                <DrawerFooter>
                  <Button type="submit">{t('common.save')}</Button>
                  <DrawerClose asChild>
                    <Button type="button" variant="outline">
                      {t('common.cancel')}
                    </Button>
                  </DrawerClose>
                </DrawerFooter>
              </form>
            </Form>
          </ScrollArea>
        </DrawerContent>
      </Drawer>
    </>
  );
}
