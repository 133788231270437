import { useAuth } from '@/core/auth';
import {
  Badge,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/shared/components/ui';
import { TimelineView } from '@/shared/components/ui/timeline';
import { cn } from '@/shared/utils';
import type { Order } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { ChevronDown, ChevronRight, Clock, MoreHorizontal } from 'lucide-react';
import { DateTime } from 'luxon';
import { forwardRef, useImperativeHandle, useState } from 'react';
import type { Id } from 'react-calendar-timeline';

import type { DeploymentData } from '../schema/deployment-schema';

interface DeploymentTimelineProps {
  deployments: Order['deployments'];
  startDate: string;
  endDate: string;
  onEdit?: (deployment: DeploymentData) => void;
  onDelete?: (deployment: DeploymentData) => void;
  pendingChangeRequests?: Record<string, { requestedBy: { name: string }; requestedAt: string }>;
}

export interface DeploymentTimelineHandle {
  handleExpandAll: () => void;
  handleCollapseAll: () => void;
}

function DeploymentGroup({
  deployment,
  isExpanded,
  onToggle,
  pendingChangeRequest,
}: {
  deployment: DeploymentData;
  isExpanded?: boolean;
  onToggle?: () => void;
  pendingChangeRequest?: { requestedBy: { name: string }; requestedAt: string };
}) {
  const { t } = useIntl();
  const hasCrewChanges = (deployment.crewChanges?.length ?? 0) > 0;

  return (
    <div className="mx-2 text-sm flex items-center gap-2 w-full min-w-0">
      {hasCrewChanges ? (
        <button onClick={onToggle} className="flex items-center gap-1 hover:bg-accent/50 rounded min-w-0 flex-1">
          <span className="text-muted-foreground shrink-0">
            {isExpanded ? <ChevronDown className="size-4" /> : <ChevronRight className="size-4" />}
          </span>
          <span className="truncate">{deployment.title}</span>
        </button>
      ) : (
        <span className="truncate flex-1">{deployment.title}</span>
      )}
      {pendingChangeRequest && (
        <Badge variant="secondary" className="gap-1 shrink-0">
          <Clock className="size-2.5" />
          <span className="text-[10px]">{t('orders.changeRequest')}</span>
        </Badge>
      )}
    </div>
  );
}

export const DeploymentTimeline = forwardRef<DeploymentTimelineHandle, DeploymentTimelineProps>(
  ({ deployments = [], startDate, endDate, onEdit, onDelete, pendingChangeRequests }, ref) => {
    const { t } = useIntl();
    const { user } = useAuth();
    const isJuhUser = user?.role === 'juh-admin' || user?.role === 'juh-employee';
    const isJuhAdmin = user?.role === 'juh-admin';
    const [expandedDeployments, setExpandedDeployments] = useState<Record<string, boolean>>({});

    const toggleDeployment = (id: string) => {
      setExpandedDeployments((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    };

    const handleExpandAll = () => {
      const allDeployments = (deployments ?? []).reduce(
        (acc, deployment) => {
          if (deployment.id && deployment.crewChanges?.length) {
            acc[deployment.id] = true;
          }
          return acc;
        },
        {} as Record<string, boolean>,
      );
      setExpandedDeployments(allDeployments);
    };

    const handleCollapseAll = () => {
      setExpandedDeployments({});
    };

    useImperativeHandle(ref, () => ({
      handleExpandAll,
      handleCollapseAll,
    }));

    const timelineGroups = (deployments ?? []).flatMap((deployment) => {
      if (!deployment.id) return [];

      const baseGroup = {
        id: deployment.id as Id,
        title: (
          <DeploymentGroup
            deployment={deployment as DeploymentData}
            isExpanded={expandedDeployments[deployment.id]}
            onToggle={() => toggleDeployment(deployment.id!)}
            pendingChangeRequest={pendingChangeRequests?.[deployment.id]}
          />
        ),
        itemProps: {
          style: {
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
          },
        },
      };

      // If deployment is expanded and has crew changes, add crew change groups
      if (expandedDeployments[deployment.id] && deployment.crewChanges?.length) {
        return [
          baseGroup,
          ...deployment.crewChanges.map((change, index) => ({
            id: `${deployment.id}-crew-${index}` as Id,
            title: (
              <div className="mx-2 text-sm flex items-center gap-2 min-w-0 pl-6 text-muted-foreground">
                <span>{t('orders.crewChange')}</span>
                <span>{DateTime.fromISO(change.dateTime).toFormat('dd.MM.yyyy HH:mm')}</span>
              </div>
            ),
          })),
        ];
      }

      return [baseGroup];
    });

    const timelineItems = (deployments ?? []).flatMap((deployment) => {
      if (!deployment.id) return [];

      const baseItem = {
        id: deployment.id as Id,
        group: deployment.id as Id,
        title: (
          <div className="flex items-center gap-2 w-full justify-between">
            <span>{deployment.title}</span>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="size-4 p-0 text-muted-foreground hover:text-foreground">
                  <span className="sr-only">{t('navigation.openMenu')}</span>
                  <MoreHorizontal className="size-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    if (onEdit) {
                      onEdit({
                        ...deployment,
                        crewChanges: deployment.crewChanges?.map((change) => ({
                          id: change.id || undefined,
                          dateTime: change.dateTime,
                        })),
                      } as DeploymentData);
                    }
                  }}
                >
                  {isJuhUser ? t('common.edit') : t('orders.requestDeploymentChange')}
                </DropdownMenuItem>
                {isJuhUser && onDelete && (
                  <DropdownMenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete({
                        ...deployment,
                        crewChanges: deployment.crewChanges?.map((change) => ({
                          id: change.id || undefined,
                          dateTime: change.dateTime,
                        })),
                      } as DeploymentData);
                    }}
                    disabled={!isJuhAdmin}
                  >
                    {t('common.delete')}
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        ),
        start_time: DateTime.fromISO(deployment.startDate).toMillis(),
        end_time: DateTime.fromISO(deployment.endDate).toMillis(),
        className: cn(
          '!bg-muted',
          pendingChangeRequests?.[deployment.id] ? '!border !border-primary/50' : '!border-none',
        ),
      };

      // If deployment is expanded and has crew changes, add crew change items
      if (expandedDeployments[deployment.id] && deployment.crewChanges?.length) {
        return [
          baseItem,
          ...deployment.crewChanges.map((change, index) => {
            const changeTime = DateTime.fromISO(change.dateTime).toMillis();
            return {
              id: `${deployment.id}-crew-${index}` as Id,
              group: `${deployment.id}-crew-${index}` as Id,
              title: (
                <div className="absolute inset-0 flex items-center justify-center">
                  <div
                    className="size-4 rounded-full bg-primary shadow-sm"
                    style={{ minWidth: '8px', minHeight: '8px' }}
                  />
                </div>
              ),
              start_time: changeTime,
              end_time: changeTime,
              className: 'timeline-item-crew-change',
              itemProps: {
                style: {
                  background: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'visible',
                  zIndex: 85, // Slightly higher than regular items to ensure visibility
                },
              },
            };
          }),
        ];
      }

      return [baseItem];
    });

    return (
      <TimelineView
        groups={timelineGroups}
        items={timelineItems}
        startTime={DateTime.fromISO(startDate).toMillis()}
        endTime={DateTime.fromISO(endDate).toMillis()}
        minZoom={24 * 60 * 60 * 1000} // 1 day
        maxZoom={7 * 24 * 60 * 60 * 1000} // 7 days
      />
    );
  },
);

DeploymentTimeline.displayName = 'DeploymentTimeline';
