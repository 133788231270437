import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Checkbox,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/shared/components/ui';
import { useIntl } from '@tiny-intl/react';
import type { Control } from 'react-hook-form';

import type { ContainerAndChildTemplatesFormValues } from '../types';

interface TemplateContainerCardProps {
  control: Control<ContainerAndChildTemplatesFormValues>;
  disabled?: boolean;
  articleCount?: number;
}

export function TemplateContainerCard({ control, disabled, articleCount }: TemplateContainerCardProps) {
  const { t } = useIntl();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('templates.container.containerTemplate')}</CardTitle>
        <CardDescription>{t('templates.container.containerTemplateDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        <FormField
          control={control}
          name="isContainer"
          render={({ field }) => (
            <FormItem className="flex flex-col space-y-4">
              <div className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                <FormControl>
                  <Checkbox
                    disabled={disabled || !!articleCount}
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>{t('templates.container.isContainer')}</FormLabel>
                  <FormDescription>{t('templates.container.isContainerDescription')}</FormDescription>
                </div>
              </div>
              {articleCount ? (
                <FormMessage className="text-xs text-muted-foreground">
                  ({t('templates.container.articleExistsError')})
                </FormMessage>
              ) : null}
            </FormItem>
          )}
        />
      </CardContent>
    </Card>
  );
}
