import { PencilLine, Plus, Trash2 } from 'lucide-react';

import type { ActivityIconProps } from './types';

export function ActivityIcon({ type }: ActivityIconProps) {
  if (type === 'created') {
    return (
      <div className="flex size-6 items-center justify-center rounded-full bg-green-500/20 text-green-500">
        <Plus className="size-3.5" />
      </div>
    );
  }
  if (type === 'deployment-updated') {
    return (
      <div className="flex size-6 items-center justify-center rounded-full bg-blue-500/20 text-blue-500">
        <PencilLine className="size-3.5" />
      </div>
    );
  }
  if (type === 'change-request-created') {
    return (
      <div className="flex size-6 items-center justify-center rounded-full bg-amber-500/20 text-amber-500">
        <PencilLine className="size-3.5" />
      </div>
    );
  }
  if (type === 'change-request-accepted') {
    return (
      <div className="flex size-6 items-center justify-center rounded-full bg-green-500/20 text-green-500">
        <PencilLine className="size-3.5" />
      </div>
    );
  }
  if (type === 'change-request-rejected') {
    return (
      <div className="flex size-6 items-center justify-center rounded-full bg-red-500/20 text-red-500">
        <PencilLine className="size-3.5" />
      </div>
    );
  }
  if (type === 'deployment-added') {
    return (
      <div className="flex size-6 items-center justify-center rounded-full bg-green-500/20 text-green-500">
        <Plus className="size-3.5" />
      </div>
    );
  }
  if (type === 'deployment-removed') {
    return (
      <div className="flex size-6 items-center justify-center rounded-full bg-red-500/20 text-red-500">
        <Trash2 className="size-3.5" />
      </div>
    );
  }
  return (
    <div className="flex size-6 items-center justify-center rounded-full bg-blue-500/20 text-blue-500">
      <PencilLine className="size-3.5" />
    </div>
  );
}
