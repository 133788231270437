import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/shared/components/ui';
import { useIntl } from '@tiny-intl/react';
import { MoreHorizontal } from 'lucide-react';

interface UserTableActionsProps {
  onEdit: () => void;
  onDelete: () => void;
  onResend: () => void;
  isInvite?: boolean;
  disabled?: boolean;
}

export const UserTableActions = ({ onEdit, onDelete, onResend, isInvite, disabled }: UserTableActionsProps) => {
  const { t } = useIntl();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button aria-haspopup="true" size="icon" variant="ghost" className="size-8" disabled={disabled}>
          <MoreHorizontal className="size-4" />
          <span className="sr-only">{t('navigation.openMenu')}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t('common.actions')}</DropdownMenuLabel>
        <DropdownMenuItem disabled={disabled} onClick={onEdit}>
          {t('common.edit')}
        </DropdownMenuItem>
        {isInvite && (
          <DropdownMenuItem disabled={disabled} onClick={onResend}>
            {t('users.resend')}
          </DropdownMenuItem>
        )}
        {isInvite && (
          <DropdownMenuItem disabled={disabled} onClick={onDelete}>
            {t('common.delete')}
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
