import { z } from 'zod';

export const jsonSchemaType = z.object({
  type: z.literal('object'),
  properties: z.record(
    z.object({
      type: z.enum(['string', 'number', 'boolean', 'date']),
      format: z.literal('date-time').optional(),
      description: z.string(),
      minLength: z.number().optional(),
      customerAccess: z.enum(['none', 'read', 'read-and-write']),
      group: z.string().nullable().optional(),
    }),
  ),
  required: z.array(z.string()),
  propertyOrder: z.array(z.string()),
});
