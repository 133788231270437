import { useAuth } from '@/core/auth';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { createRoutes } from './routes';

export function AppRouter() {
  const auth = useAuth();

  if (auth.initialized) {
    const router = createBrowserRouter(createRoutes(auth.user), {
      basename: import.meta.env.VITE_APP_ROUTER_BASE || undefined,
    });

    return <RouterProvider router={router} />;
  }

  return (
    <div className="flex justify-center items-center w-screen h-screen">
      <div
        className="rounded-full border-2 border-current animate-spin text-primary size-4 border-t-transparent"
        aria-label="loading"
      ></div>
    </div>
  );
}

export * from './navigation-routes';
