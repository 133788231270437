import { useIntl } from '@tiny-intl/react';
import { MoreHorizontal } from 'lucide-react';

import { ActivityIcon } from './activity-icon';
import type { CustomActivityTimelineProps } from './types';

export function CustomActivityTimeline({
  activities,
  renderActivity,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
}: CustomActivityTimelineProps) {
  const { t } = useIntl();

  return (
    <div className="relative space-y-0">
      {activities.map((activity, index) => (
        <div key={activity.id} className="relative flex gap-3 py-3 text-sm">
          <div className="relative">
            <ActivityIcon type={activity.type} />
            {(index !== activities.length - 1 || hasNextPage) && (
              <div className="absolute left-1/2 top-6 h-full w-px -translate-x-1/2 bg-border" />
            )}
          </div>
          <div className="flex-1 mt-0.5">{renderActivity(activity)}</div>
        </div>
      ))}
      {hasNextPage && (
        <div className="relative flex gap-3 py-3 text-sm">
          <div className="relative">
            <div className="flex size-6 items-center justify-center rounded-full bg-muted text-muted-foreground">
              <MoreHorizontal className="size-3.5" />
            </div>
          </div>
          <div className="flex-1 mt-0.5">
            <button
              type="button"
              onClick={() => fetchNextPage?.()}
              disabled={isFetchingNextPage}
              className="text-muted-foreground hover:text-foreground disabled:opacity-50"
            >
              {isFetchingNextPage ? t('activity.loadingMore') : t('activity.loadMore')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
