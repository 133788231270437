import { useAuth } from '@/core/auth';
import { TableLoadingCell } from '@/shared/components/tables';
import { Badge, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/shared/components/ui';
import type { Invite, User } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';

import { UserTableActions } from './user-table-actions';

interface UserTableProps {
  users: (User | (Invite & { isInvite: true }))[];
  onEdit: (user: User | (Invite & { isInvite: true })) => void;
  onDelete: (user: User | (Invite & { isInvite: true })) => void;
  onResend: (user: User | (Invite & { isInvite: true })) => void;
  isLoading?: boolean;
}

export const UserTable = ({ users, onEdit, onDelete, onResend, isLoading }: UserTableProps) => {
  const { t } = useIntl();
  const { user: currentUser } = useAuth();
  const isJuhUser = currentUser?.role.startsWith('juh-');

  const onlyReadPermissions = currentUser?.role === 'customer-employee' || currentUser?.role === 'juh-employee';

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t('common.name')}</TableHead>
          <TableHead>{t('auth.email')}</TableHead>
          <TableHead>{t('users.role')}</TableHead>
          <TableHead>{t('common.status')}</TableHead>
          {isJuhUser && <TableHead>{t('users.certificateAccess')}</TableHead>}
          <TableHead className="text-right">
            <span className="sr-only">{t('common.actions')}</span>
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {isLoading ? (
          <TableLoadingCell colSpan={isJuhUser ? 6 : 5} />
        ) : (
          users.map((user) => (
            <TableRow key={user.id}>
              <TableCell className="truncate">{`${user.firstName} ${user.lastName}`}</TableCell>
              <TableCell className="max-w-[200px] truncate">{user.email}</TableCell>
              <TableCell className="">
                <Badge variant={user.role === 'customer-admin' ? 'default' : 'secondary'}>
                  {t(`portalRoles.${user.role}`)}
                </Badge>
              </TableCell>
              <TableCell>
                {'isInvite' in user && user.isInvite ? (
                  <Badge variant="outline">{t('users.invited')}</Badge>
                ) : (
                  <Badge>{t('users.active')}</Badge>
                )}
              </TableCell>
              {isJuhUser && (
                <TableCell>
                  <Badge variant={user.certificateAccess ? 'default' : 'secondary'}>
                    {user.certificateAccess ? t('common.yes') : t('common.no')}
                  </Badge>
                </TableCell>
              )}
              <TableCell className="text-right">
                <UserTableActions
                  onEdit={() => onEdit(user)}
                  onDelete={() => onDelete(user)}
                  onResend={() => onResend(user)}
                  isInvite={'isInvite' in user && user.isInvite}
                  disabled={onlyReadPermissions}
                />
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};
