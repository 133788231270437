import { useDeleteObject, useObjects } from '@/core/api';
import { useAuth } from '@/core/auth';
import { SearchField } from '@/shared/components/inputs';
import { PageHeading } from '@/shared/components/layout';
import { DataTablePagination, TableLoadingCell } from '@/shared/components/tables';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  EmptyTableRow,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/shared/components/ui';
import { useDebounce } from '@/shared/hooks/use-debounce';
import { useTablePreferences } from '@/shared/services/table-preferences';
import type { Object } from '@johanniter-offshore/backend';
import type { ColumnDef, ColumnFiltersState, SortingState, VisibilityState } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useIntl } from '@tiny-intl/react';
import { MoreHorizontal, PlusCircle } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

const ActionCell = ({ object, onDelete }: { object: Object; onDelete: (object: Object) => void }) => {
  const navigate = useNavigate();
  const { t } = useIntl();
  const { user: currentUser } = useAuth();
  const isJuhAdmin = currentUser?.role === 'juh-admin';

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="size-8 p-0">
          <span className="sr-only">{t('navigation.openMenu')}</span>
          <MoreHorizontal className="size-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t('common.actions')}</DropdownMenuLabel>
        <DropdownMenuItem onClick={() => navigate(`/juh/order-management/objects/${object.id}`)}>
          {t('common.edit')}
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => navigate(`/juh/order-management/objects/new?copy=${object.id}`)}
          disabled={!isJuhAdmin}
          title={!isJuhAdmin ? t('objects.onlyJuhAdminCanCopy') : undefined}
        >
          {t('objects.copyObject')}
        </DropdownMenuItem>
        {isJuhAdmin && <DropdownMenuItem onClick={() => onDelete(object)}>{t('common.delete')}</DropdownMenuItem>}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export function ObjectsOverview() {
  const { t } = useIntl();
  const navigate = useNavigate();
  const { user: currentUser } = useAuth();
  const isJuhAdmin = currentUser?.role === 'juh-admin';

  const tablePreferences = useTablePreferences({
    tableId: 'objects-overview',
    defaultPreferences: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
      sorting: [],
      columnFilters: [],
      columnVisibility: {},
      searchTerm: '',
    },
  });

  // Load initial preferences
  const preferences = tablePreferences.loadPreferences();

  const [searchTerm, setSearchTerm] = useState(preferences.searchTerm || '');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [sorting, setSorting] = useState<SortingState>(preferences.sorting || []);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(preferences.columnFilters || []);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(preferences.columnVisibility || {});
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState(
    preferences.pagination || {
      pageIndex: 0,
      pageSize: 10,
    },
  );

  // Save preferences when they change
  useEffect(() => {
    tablePreferences.savePreferences({
      pagination,
      sorting,
      columnFilters,
      columnVisibility,
      searchTerm,
    });
  }, [pagination, sorting, columnFilters, columnVisibility, searchTerm, tablePreferences]);

  const handleSearchChange = useCallback((value: string) => {
    setSearchTerm(value);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  }, []);

  const [objectToDelete, setObjectToDelete] = useState<Object | null>(null);
  const deleteObject = useDeleteObject();

  const handleDelete = async () => {
    if (objectToDelete) {
      try {
        await deleteObject.mutateAsync(objectToDelete.id);
        toast.success(t('objects.objectDeletedSuccessfully'));
        setObjectToDelete(null);
      } catch (error) {
        toast.error(t('objects.errorDeletingObject'));
      }
    }
  };

  const columns: ColumnDef<Object>[] = [
    {
      accessorKey: 'name',
      header: t('common.name'),
      cell: ({ row }) => <div>{row.getValue('name')}</div>,
    },
    {
      accessorKey: 'description',
      header: t('common.description'),
      cell: ({ row }) => <div>{row.getValue('description')}</div>,
    },
    {
      id: 'actions',
      cell: ({ row }) => <ActionCell object={row.original} onDelete={setObjectToDelete} />,
    },
  ];

  const { data: objectsData, isLoading } = useObjects({
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    where: debouncedSearchTerm ? { name: { like: debouncedSearchTerm } } : undefined,
  });

  const objects = objectsData?.docs || [];
  const totalPages = objectsData?.totalPages || 0;

  const table = useReactTable({
    data: objects,
    columns,
    pageCount: totalPages,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination,
    },
    onPaginationChange: setPagination,
    manualPagination: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <>
      <PageHeading>{t('objects.objects')}</PageHeading>

      <div className="flex items-center">
        <SearchField searchTerm={searchTerm} setSearchTerm={handleSearchChange} />
        <div className="ml-auto">
          <Button onClick={() => navigate('/juh/order-management/objects/new')} disabled={!isJuhAdmin}>
            <PlusCircle className="size-4 mr-2" />
            <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">{t('objects.createObject')}</span>
          </Button>
        </div>
      </div>

      <div className="space-y-4">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                  ))}
                </TableRow>
              ))
            ) : !isLoading ? (
              <EmptyTableRow colSpan={columns.length} />
            ) : (
              <TableLoadingCell colSpan={columns.length} />
            )}
          </TableBody>
        </Table>

        <DataTablePagination table={table} />
      </div>

      <AlertDialog open={!!objectToDelete} onOpenChange={(open) => !open && setObjectToDelete(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('objects.deleteObject')}</AlertDialogTitle>
            <AlertDialogDescription>
              {t('objects.deleteObjectConfirmation', { name: objectToDelete?.name || t('objects.object') })}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
            <AlertDialogAction onClick={handleDelete} disabled={deleteObject.isPending}>
              {deleteObject.isPending ? t('common.deleting') : t('common.delete')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
