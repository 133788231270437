import { ResolvedUser } from '@/shared/components/activity';
import { useIntl } from '@tiny-intl/react';
import { DateTime } from 'luxon';

import { ResolvedFieldValue } from './resolved-field-value';
import type { ChangeRequestActivityProps } from './types';
import { extractDeploymentDataForChangeRequest } from './utils';

export function ChangeRequestActivity({ activity, order, changeRequest }: ChangeRequestActivityProps) {
  const { t, locale } = useIntl();

  const deploymentForProcessing = activity.previousDeployment;
  const processedChangeRequest =
    deploymentForProcessing && changeRequest && (changeRequest.changes || activity.changeRequestChanges)
      ? extractDeploymentDataForChangeRequest(deploymentForProcessing, {
          ...(changeRequest as unknown as Record<string, unknown>),
          // If the activity has change request changes, merge them
          ...(activity.changeRequestChanges || {}),
        })
      : null;

  if (!changeRequest) {
    return (
      <div className="flex items-center gap-1">
        <ResolvedUser userId={activity.user!} />
        <span className="text-muted-foreground">
          {t('activity.changeRequest.unknown')}
          <span className="font-medium"> {activity.deploymentTitle}</span>
        </span>
        <span className="text-muted-foreground">· {DateTime.fromISO(activity.timestamp).toRelative({ locale })}</span>
      </div>
    );
  }

  switch (activity.type) {
    case 'change-request-created':
      return (
        <div className="flex flex-col gap-1 border-l-2 border-amber-500/20 pl-2">
          <div className="flex items-center gap-1">
            <ResolvedUser userId={activity.user!} />
            <span className="text-muted-foreground">
              {t('activity.changeRequest.created')}
              <span className="font-medium"> {activity.deploymentTitle}</span>
            </span>
            <span className="text-muted-foreground">
              · {DateTime.fromISO(activity.timestamp).toRelative({ locale })}
            </span>
          </div>

          {/* Render change request changes */}
          {Object.entries(changeRequest.changes || {}).map(([field, value], index) => {
            // Skip deployment ID field and fields that didn't actually change
            if (field === 'id') return null;
            if (
              field !== 'crewChanges' &&
              processedChangeRequest &&
              !processedChangeRequest.changedFields.includes(field)
            ) {
              return null;
            }

            // Handle crew changes differently
            if (field === 'crewChanges' && processedChangeRequest) {
              const crewChanges = processedChangeRequest.crewChanges;

              if (!crewChanges || crewChanges.length === 0) {
                return null; // No actual crew changes
              }

              return (
                <div key={index} className="ml-5 flex flex-col gap-1">
                  <div className="flex items-center gap-1 text-sm">
                    <span className="text-muted-foreground">{t('activity.changeRequest.crewChangesRequested')}:</span>
                  </div>
                  {crewChanges.map((crewChange, crewIndex) => {
                    // Handle different action types
                    if (crewChange.action === 'delete') {
                      return (
                        <div key={`crew-${crewIndex}`} className="ml-5 flex items-center gap-1 text-sm">
                          <span className="text-muted-foreground">
                            {t('activity.changeRequest.crewChangeRemoved')}{' '}
                          </span>
                          <span className="font-medium">
                            <ResolvedFieldValue field="crewChange-removed" value={crewChange.dateTime} order={order} />
                          </span>
                        </div>
                      );
                    } else if (crewChange.action === 'modify' && crewChange.originalDateTime) {
                      return (
                        <div key={`crew-${crewIndex}`} className="ml-5 flex items-center gap-1 text-sm">
                          <span className="text-muted-foreground">
                            {t('activity.changeRequest.crewChangeModified')}{' '}
                          </span>
                          <span className="font-medium">
                            <ResolvedFieldValue
                              field="crewChange-modified"
                              value={crewChange.originalDateTime}
                              order={order}
                            />
                          </span>
                          <span className="text-muted-foreground">{t('activity.to')} </span>
                          <span className="font-medium">
                            <ResolvedFieldValue field="crewChange-modified" value={crewChange.dateTime} order={order} />
                          </span>
                        </div>
                      );
                    } else {
                      // 'add' action
                      return (
                        <div key={`crew-${crewIndex}`} className="ml-5 flex items-center gap-1 text-sm">
                          <span className="text-muted-foreground">{t('activity.changeRequest.crewChangeAdded')} </span>
                          <span className="font-medium">
                            <ResolvedFieldValue field="crewChange-added" value={crewChange.dateTime} order={order} />
                          </span>
                        </div>
                      );
                    }
                  })}
                </div>
              );
            }

            // Get field label
            const fieldLabel = t(`activity.deployment.fields.${field}`);

            // Get original value from deployment when available
            if (processedChangeRequest?.originalDeployment && deploymentForProcessing) {
              const originalDeploymentAsRecord = processedChangeRequest.originalDeployment as unknown as Record<
                string,
                unknown
              >;
              const originalValue = field in originalDeploymentAsRecord ? originalDeploymentAsRecord[field] : undefined;

              return (
                <div key={index} className="ml-5 flex items-center gap-1 text-sm">
                  <span className="text-muted-foreground">{t('activity.changeRequest.fieldChangeRequested')}</span>
                  <span className="font-medium">{fieldLabel}</span>
                  <span className="text-muted-foreground">{t('activity.from')}</span>
                  <span className="font-medium">
                    <ResolvedFieldValue field={field} value={originalValue} order={order} />
                  </span>
                  <span className="text-muted-foreground">{t('activity.to')}</span>
                  <span className="font-medium">
                    <ResolvedFieldValue field={field} value={value} order={order} />
                  </span>
                </div>
              );
            }

            // Fallback to original implementation if we can't determine the original value
            return (
              <div key={index} className="ml-5 flex items-center gap-1 text-sm">
                <span className="text-muted-foreground">{t('activity.changeRequest.fieldChangeRequested')}</span>
                <span className="font-medium">{fieldLabel}</span>
                <span className="text-muted-foreground">{t('activity.to')}</span>
                <span className="font-medium">
                  <ResolvedFieldValue field={field} value={value} order={order} />
                </span>
              </div>
            );
          })}
        </div>
      );

    case 'change-request-accepted':
      return (
        <div className="flex flex-col gap-1 border-l-2 border-green-500/20 pl-2">
          <div className="flex items-center gap-1">
            <ResolvedUser userId={activity.user!} />
            <span className="text-muted-foreground">
              {t('activity.changeRequest.accepted')}
              <span className="font-medium"> {activity.deploymentTitle}</span>
            </span>
            <span className="text-muted-foreground">
              · {DateTime.fromISO(activity.timestamp).toRelative({ locale })}
            </span>
          </div>
        </div>
      );

    case 'change-request-rejected':
      return (
        <div className="flex flex-col gap-1 border-l-2 border-red-500/20 pl-2">
          <div className="flex items-center gap-1">
            <ResolvedUser userId={activity.user!} />
            <span className="text-muted-foreground">
              {t('activity.changeRequest.rejected')}
              <span className="font-medium"> {activity.deploymentTitle}</span>
            </span>
            <span className="text-muted-foreground">
              · {DateTime.fromISO(activity.timestamp).toRelative({ locale })}
            </span>
          </div>
        </div>
      );

    default:
      return null;
  }
}
