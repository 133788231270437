import type { PaginatedDocs } from '@/core/api';
import { MetadataBuilder } from '@/shared/components/metadata';
import { Card, CardContent, CardDescription, CardHeader, CardTitle, Form, Separator } from '@/shared/components/ui';
import type { Category } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import type { UseFormReturn } from 'react-hook-form';

import type {
  CategoryFormValues,
  ContainerAndChildTemplatesFormValues,
  DetailsFormValues,
  InspectionsFormValues,
  MetadataFormValues,
  TemplateFilesFormValues,
} from '../types';
import {
  TemplateCategoryCard,
  TemplateChildCard,
  TemplateContainerCard,
  TemplateDetailsCard,
  TemplateFilesCard,
  TemplateInspectionsCard,
} from './';

interface TemplateFormProps {
  detailsForm: UseFormReturn<DetailsFormValues>;
  categoryForm: UseFormReturn<CategoryFormValues>;
  inspectionsForm: UseFormReturn<InspectionsFormValues>;
  metadataForm: UseFormReturn<MetadataFormValues>;
  containerAndChildTemplatesForm: UseFormReturn<ContainerAndChildTemplatesFormValues>;
  templateFilesForm: UseFormReturn<TemplateFilesFormValues>;
  categoriesData?: PaginatedDocs<Category>;
  currentTemplateId?: string;
  articleCount?: number;
}

function TemplateFormLayout({ children }: { children: React.ReactNode }) {
  return (
    <div className="grid gap-6">
      <div className="grid grid-cols-12 gap-6">{children}</div>
    </div>
  );
}

export function TemplateForm({
  detailsForm,
  categoryForm,
  inspectionsForm,
  metadataForm,
  containerAndChildTemplatesForm,
  templateFilesForm,
  categoriesData,
  currentTemplateId,
  articleCount,
}: TemplateFormProps) {
  const { t } = useIntl();

  return (
    <TemplateFormLayout>
      {/* Left Column */}
      <div className="col-span-8 space-y-6">
        <Form {...detailsForm}>
          <TemplateDetailsCard control={detailsForm.control} />
        </Form>

        <Form {...inspectionsForm}>
          <TemplateInspectionsCard control={inspectionsForm.control} />
        </Form>
      </div>

      {/* Right Column */}
      <div className="col-span-4 space-y-6">
        <Form {...categoryForm}>
          <TemplateCategoryCard
            control={categoryForm.control}
            setValue={categoryForm.setValue}
            categoriesData={categoriesData}
          />
        </Form>

        <Form {...templateFilesForm}>
          <TemplateFilesCard />
        </Form>
      </div>

      <div className="col-span-12 flex flex-col gap-6">
        <Form {...metadataForm}>
          <Card>
            <CardHeader>
              <CardTitle>{t('metadata.metadata')}</CardTitle>
              <CardDescription>{t('templates.templateMetadataDescription')}</CardDescription>
            </CardHeader>
            <CardContent className="p-0">
              <MetadataBuilder />
            </CardContent>
          </Card>
        </Form>
      </div>
      <div className="col-span-12 flex flex-col gap-6">
        <Separator />

        <Form {...containerAndChildTemplatesForm}>
          <TemplateContainerCard
            control={containerAndChildTemplatesForm.control}
            disabled={!!articleCount}
            articleCount={articleCount}
          />
          {containerAndChildTemplatesForm.watch('isContainer') && (
            <TemplateChildCard
              control={containerAndChildTemplatesForm.control}
              currentTemplateId={currentTemplateId}
              disabled={!!articleCount}
            />
          )}
        </Form>
      </div>
    </TemplateFormLayout>
  );
}
