import { useCreateObject, useObject, useUpdateObject } from '@/core/api';
import { useAuth } from '@/core/auth';
import { PageHeader } from '@/shared/components/layout/page-header';
import { MetadataAutoForm } from '@/shared/components/metadata/metadata-auto-form';
import {
  MetadataBuilder,
  type MetadataFormField,
  handleMetadataBuilderSubmit as handleMetadataSubmit,
  jsonSchemaToFormFields,
  parseMetadata,
  preserveMetadataGroups,
} from '@/shared/components/metadata/metadata-builder';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  ScrollArea,
  Textarea,
} from '@/shared/components/ui';
import { createMetadataZodSchema } from '@/shared/utils/metadata';
import { zodResolver } from '@hookform/resolvers/zod';
import { useIntl } from '@tiny-intl/react';
import { Edit3 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { z } from 'zod';

import { createObjectSchema } from '../schema';
import type { ObjectFormValues } from '../types';

export function ManageObject() {
  const { t } = useIntl();
  const { user: currentUser } = useAuth();
  const createObject = useCreateObject();
  const updateObject = useUpdateObject();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const copyFromId = searchParams.get('copy');
  const isEditMode = !!id;
  const isCopyMode = !!copyFromId;

  const { data: object } = useObject(id || '');
  const { data: objectToCopy } = useObject(copyFromId || '', { disabled: !copyFromId });
  const isJuhAdmin = currentUser?.role === 'juh-admin';
  const isCustomer = !isJuhAdmin;

  const [isMetadataDrawerOpen, setIsMetadataDrawerOpen] = useState(false);
  const [dynamicMetadataSchema, setDynamicMetadataSchema] = useState<
    z.ZodObject<z.ZodRawShape, z.UnknownKeysParam, z.ZodTypeAny>
  >(z.object({}));

  const form = useForm<ObjectFormValues>({
    resolver: zodResolver(createObjectSchema(t, dynamicMetadataSchema)),
    defaultValues: {
      name: '',
      description: '',
      dynamicMetadataSchema: {
        type: 'object',
        properties: {},
        required: [],
        propertyOrder: [],
      },
      dynamicMetadataValues: {},
    },
  });

  useEffect(() => {
    if (isEditMode && object) {
      form.reset({
        name: object.name,
        description: object.description || '',
        dynamicMetadataSchema: object.dynamicMetadataSchema || {
          type: 'object',
          properties: {},
          required: [],
          propertyOrder: [],
        },
        dynamicMetadataValues: parseMetadata(object.dynamicMetadataValues as Record<string, unknown>),
      });
    } else if (isCopyMode && objectToCopy) {
      form.reset({
        name: `${objectToCopy.name} (${t('common.copy')})`,
        description: objectToCopy.description || '',
        dynamicMetadataSchema: objectToCopy.dynamicMetadataSchema || {
          type: 'object',
          properties: {},
          required: [],
          propertyOrder: [],
        },
        dynamicMetadataValues: parseMetadata(objectToCopy.dynamicMetadataValues as Record<string, unknown>),
      });
    }
  }, [isEditMode, isCopyMode, object, objectToCopy, form, t]);

  useEffect(() => {
    if (form.watch('dynamicMetadataSchema')) {
      setDynamicMetadataSchema(createMetadataZodSchema(form.watch('dynamicMetadataSchema'), !isCustomer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.watch('dynamicMetadataSchema'), isCustomer]);

  const metadataBuilderForm = useForm<{ metadata: MetadataFormField[] }>({
    defaultValues: {
      metadata: form.watch('dynamicMetadataSchema')
        ? jsonSchemaToFormFields(form.watch('dynamicMetadataSchema'))
        : [
            {
              key: `tempkey-${Date.now()}`,
              label: '',
              type: 'string',
              required: false,
              customerAccess: 'read',
              group: null,
            },
          ],
    },
  });

  // Update metadata builder form when schema changes
  useEffect(() => {
    if (form.watch('dynamicMetadataSchema')) {
      metadataBuilderForm.reset({
        metadata: jsonSchemaToFormFields(form.watch('dynamicMetadataSchema')),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.watch('dynamicMetadataSchema'), metadataBuilderForm]);

  const handleMetadataBuilderSubmit = async (data: { metadata: MetadataFormField[] }) => {
    handleMetadataSubmit(data, form, metadataBuilderForm, setDynamicMetadataSchema, () =>
      setIsMetadataDrawerOpen(false),
    );
  };

  const handleSubmit = async (data: ObjectFormValues) => {
    if (!isJuhAdmin) return;

    // If we have dynamic metadata fields, ensure we preserve the group information
    if (data.dynamicMetadataSchema) {
      data.dynamicMetadataSchema = preserveMetadataGroups(
        data.dynamicMetadataSchema,
        metadataBuilderForm.getValues().metadata,
      );
    }

    try {
      if (isEditMode) {
        await updateObject.mutateAsync({ id, data });
        toast.success(t('objects.objectUpdatedSuccessfully'));
      } else {
        const createdObject = await createObject.mutateAsync(data);
        toast.success(t('objects.objectCreatedSuccessfully'));
        navigate(`/juh/order-management/objects/${createdObject.doc.id}`);
      }
    } catch (error) {
      toast.error(t('objects.errorSavingObject'));
    }
  };

  return (
    <>
      <PageHeader
        title={isEditMode ? t('objects.editObject') : isCopyMode ? t('objects.copyObject') : t('objects.newObject')}
        backHref="/juh/order-management/objects"
        actions={
          <div className="flex items-center gap-2">
            <Button variant="outline" onClick={() => navigate('/juh/order-management/objects')}>
              {t('common.discard')}
            </Button>
            <Button
              onClick={form.handleSubmit(handleSubmit)}
              disabled={(isEditMode ? updateObject.isPending : createObject.isPending) || !isJuhAdmin}
            >
              {isEditMode
                ? updateObject.isPending
                  ? t('common.updating')
                  : t('common.update')
                : createObject.isPending
                  ? t('common.creating')
                  : t('common.create')}
            </Button>
          </div>
        }
      />

      <div className="grid gap-6">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="grid gap-6 md:grid-cols-2">
            <Card className="md:col-span-2">
              <CardHeader>
                <CardTitle>{t('objects.objectDetails')}</CardTitle>
                <CardDescription>{t('objects.objectDetailsDescription')}</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel required>{t('common.name')}</FormLabel>
                        <FormControl>
                          <Input {...field} disabled={!isJuhAdmin} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t('common.description')}</FormLabel>
                        <FormControl>
                          <Textarea {...field} disabled={!isJuhAdmin} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </CardContent>
            </Card>

            <Card className="md:col-span-2">
              <CardHeader className="flex flex-row items-center justify-between space-y-0">
                <div className="space-y-1">
                  <CardTitle>{t('metadata.customMetadata')}</CardTitle>
                  <CardDescription>{t('metadata.customMetadataBuilderDescription')}</CardDescription>
                </div>
                {!isCustomer && (
                  <Button
                    variant="outline"
                    size="sm"
                    className="ml-auto"
                    onClick={() => setIsMetadataDrawerOpen(true)}
                    type="button"
                  >
                    <Edit3 className="!size-3" />
                    {t('metadata.addCustomMetadata')}
                  </Button>
                )}
              </CardHeader>
              <CardContent>
                {form.watch('dynamicMetadataSchema') &&
                Object.keys(form.watch('dynamicMetadataSchema.properties') || {}).length > 0 ? (
                  <MetadataAutoForm
                    schema={dynamicMetadataSchema}
                    form={form}
                    path={['dynamicMetadataValues']}
                    metadataSchema={form.watch('dynamicMetadataSchema')}
                    isCustomer={isCustomer}
                  />
                ) : (
                  <div className="text-sm text-muted-foreground">({t('metadata.noMetadata')})</div>
                )}
              </CardContent>
            </Card>
          </form>
        </Form>
      </div>

      <Drawer open={isMetadataDrawerOpen} onOpenChange={setIsMetadataDrawerOpen} direction="right">
        <DrawerContent className="left-auto right-0 top-0 mt-0 h-screen w-full rounded-none max-w-[1200px]">
          <ScrollArea className="h-screen">
            <DrawerHeader>
              <DrawerTitle>{t('metadata.customMetadataBuilder')}</DrawerTitle>
              <DrawerDescription>{t('metadata.customMetadataBuilderDescription')}</DrawerDescription>
            </DrawerHeader>
            <Form {...metadataBuilderForm}>
              <form onSubmit={metadataBuilderForm.handleSubmit(handleMetadataBuilderSubmit)}>
                <div className="space-y-4 p-4">
                  <MetadataBuilder allowedCustomerAccess={['none', 'read']} />
                </div>
                <DrawerFooter>
                  <Button type="submit">{t('common.save')}</Button>
                  <DrawerClose asChild>
                    <Button type="button" variant="outline">
                      {t('common.cancel')}
                    </Button>
                  </DrawerClose>
                </DrawerFooter>
              </form>
            </Form>
          </ScrollArea>
        </DrawerContent>
      </Drawer>
    </>
  );
}
