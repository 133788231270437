import { ResolvedUser } from '@/shared/components/activity';
import { useIntl } from '@tiny-intl/react';
import { DateTime } from 'luxon';

import { ResolvedFieldValue } from './resolved-field-value';
import type { DeploymentChangeProps } from './types';

export function DeploymentChange({ activity, order }: DeploymentChangeProps) {
  const { t, locale } = useIntl();

  return (
    <div className="flex flex-col gap-1 border-l-2 border-blue-500/20 pl-2">
      <div className="flex items-center gap-1">
        <ResolvedUser userId={activity.user!} />
        <span className="text-muted-foreground">
          {t('activity.deployment.changed')}
          <span className="font-medium"> {activity.deploymentTitle}</span>
        </span>
        <span className="text-muted-foreground">· {DateTime.fromISO(activity.timestamp).toRelative({ locale })}</span>
      </div>

      {activity.changes?.map((change, index) => {
        let fieldNameKey = '';

        if (change.field === 'crewChange-added') {
          return (
            <div key={index} className="ml-5 flex items-center gap-1 text-sm">
              <span className="text-muted-foreground">{t('activity.deployment.crewChangeAdded')}</span>
              <span className="font-medium">
                <ResolvedFieldValue field={change.field} value={change.to} order={order} />
              </span>
              <span className="text-muted-foreground">{t('activity.deployment.toDeployment')}</span>
            </div>
          );
        } else if (change.field === 'crewChange-removed') {
          return (
            <div key={index} className="ml-5 flex items-center gap-1 text-sm">
              <span className="text-muted-foreground">{t('activity.deployment.crewChangeRemoved')}</span>
              <span className="font-medium">
                <ResolvedFieldValue field={change.field} value={change.from} order={order} />
              </span>
              <span className="text-muted-foreground">{t('activity.deployment.fromDeployment')}</span>
            </div>
          );
        } else if (change.field === 'crewChange-modified') {
          return (
            <div key={index} className="ml-5 flex items-center gap-1 text-sm">
              <span className="text-muted-foreground">{t('activity.deployment.crewChangeModified')}</span>
              <span className="font-medium">
                <ResolvedFieldValue field={change.field} value={change.from} order={order} />
              </span>
              <span className="text-muted-foreground">{t('activity.deployment.inDeployment')}</span>
              <span className="text-muted-foreground">{t('activity.to')}</span>
              <span className="font-medium">
                <ResolvedFieldValue field={change.field} value={change.to} order={order} />
              </span>
            </div>
          );
        }

        fieldNameKey = `activity.deployment.fields.${change.field}`;

        return (
          <div key={index} className="ml-5 flex items-center gap-1 text-sm">
            <span className="text-muted-foreground">{t('activity.changed')}</span>
            <span className="font-medium">{t(fieldNameKey)}</span>
            <span className="text-muted-foreground">{t('activity.from')}</span>
            <span className="font-medium">
              <ResolvedFieldValue field={change.field} value={change.from} order={order} />
            </span>
            <span className="text-muted-foreground">{t('activity.to')}</span>
            <span className="font-medium">
              <ResolvedFieldValue field={change.field} value={change.to} order={order} />
            </span>
          </div>
        );
      })}
    </div>
  );
}
