import { useCreateOrder, useOrder, useUpdateOrder } from '@/core/api';
import type { PayloadApiError } from '@/core/api/client';
import { useGlobalMetadata } from '@/core/api/global-metadata';
import { useAuth } from '@/core/auth';
import { PageHeader } from '@/shared/components/layout/page-header';
import { TabNav } from '@/shared/components/layout/tab-nav';
import { Separator } from '@/shared/components/ui';
import { useIntl } from '@tiny-intl/react';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { ArticleRentalAssignedArticles } from '../components/article-rental-assigned-articles';
import { OrderActivity } from '../components/order-activity';
import { OrderForm } from '../components/order-form';
import type { OrderFormData } from '../types/order-form';

export function ArticleRentalDetails() {
  const { t } = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: parentId, subOrderId } = useParams();
  const { user } = useAuth();
  const isEditMode = subOrderId !== 'new';
  const { data: parentOrder } = useOrder(parentId!);
  const { data: subOrder } = useOrder(subOrderId!, { disabled: !isEditMode });
  const [error, setError] = useState<PayloadApiError | undefined>();

  const createOrder = useCreateOrder();
  const updateOrder = useUpdateOrder();
  const isJuhUser = user?.role === 'juh-admin' || user?.role === 'juh-employee';
  const isJuhAdmin = user?.role === 'juh-admin';
  const basePath = isJuhUser ? '/juh/order-management/orders' : '/c/my-orders';
  const { data: globalMetadata } = useGlobalMetadata();

  const currentTab = location.pathname.split('/').pop();
  const showActions = !isEditMode || currentTab === 'general';

  const tabs = [
    { value: 'general', label: t('common.general') },
    ...(isJuhAdmin ? [{ value: 'activity', label: t('common.activity') }] : []),
  ];

  const handleSubmit = async (data: OrderFormData) => {
    try {
      setError(undefined);
      if (isEditMode && subOrder) {
        if (!isJuhAdmin) {
          if (isJuhUser) {
            toast.error(t('common.insufficientPermissions'));
            return;
          }
          await updateOrder.mutateAsync({
            id: subOrder.id,
            data: {
              metadata: data.metadata,
              dynamicMetadataValues: data.dynamicMetadataValues,
            },
          });
        } else {
          await updateOrder.mutateAsync({ id: subOrder.id, data });
        }
      } else {
        if (!isJuhAdmin) {
          toast.error(t('common.insufficientPermissions'));
          return;
        }
        const response = await createOrder.mutateAsync({
          ...data,
          type: 'article-rental',
          parent: parentOrder!.id,
          organization: parentOrder!.organization,
          object: parentOrder!.object,
        });
        navigate(`${basePath}/${parentOrder!.id}/sub-orders/${response.doc.id}/article-rental/general`);
      }
      toast.success(t('common.savedSuccessfully'));
    } catch (err) {
      setError(err as PayloadApiError);
      toast.error(t('common.errorSaving'));
    }
  };

  if (!parentOrder || (isEditMode && !subOrder)) {
    return null;
  }

  // Check both global and dynamic metadata schemas for editable fields
  const hasEditableMetadata = Boolean(
    // Check global metadata schema
    (globalMetadata?.articleRentalOrderMetadata?.properties &&
      Object.entries(globalMetadata.articleRentalOrderMetadata.properties).some(
        ([, prop]: [string, { customerAccess?: string }]) => prop.customerAccess === 'read-and-write',
      )) ||
      // Check dynamic metadata schema
      (subOrder?.dynamicMetadataSchema?.properties &&
        Object.entries(subOrder.dynamicMetadataSchema.properties).some(
          ([, prop]: [string, { customerAccess?: string }]) => prop.customerAccess === 'read-and-write',
        )),
  );

  return (
    <>
      <PageHeader
        title={isJuhUser ? t(isEditMode ? 'orders.editSubOrder' : 'orders.newSubOrder') : t('orders.subOrderDetails')}
        backHref={`${basePath}/${parentId}`}
        primaryAction={
          showActions
            ? isJuhUser
              ? {
                  label: isEditMode ? t('common.update') : t('common.create'),
                  loadingLabel: isEditMode ? t('common.updating') : t('common.creating'),
                  form: 'sub-order-form',
                  type: 'submit',
                  disabled: (isEditMode ? updateOrder.isPending : createOrder.isPending) || !isJuhAdmin,
                  isLoading: isEditMode ? updateOrder.isPending : createOrder.isPending,
                }
              : hasEditableMetadata
                ? {
                    label: t('common.update'),
                    loadingLabel: t('common.updating'),
                    form: 'sub-order-form',
                    type: 'submit',
                    disabled: updateOrder.isPending,
                    isLoading: updateOrder.isPending,
                  }
                : undefined
            : undefined
        }
        secondaryAction={
          showActions && isJuhUser
            ? {
                label: t('common.discard'),
                onClick: () => navigate(`${basePath}/${parentId}`),
                variant: 'outline',
              }
            : undefined
        }
      />

      {isEditMode && (
        <TabNav tabs={tabs} basePath={`${basePath}/${parentId}/sub-orders/${subOrderId}/article-rental`} />
      )}

      <div className="grid gap-6">
        {(!isEditMode || currentTab === 'general') && (
          <>
            <OrderForm
              id="sub-order-form"
              defaultValues={subOrder || { organization: parentOrder.organization }}
              onSubmit={handleSubmit}
              error={error}
              orderType="article-rental"
              parentStartDate={parentOrder.startDate}
              parentEndDate={parentOrder.endDate}
            />
            {isEditMode && subOrder && (
              <>
                <Separator />
                <ArticleRentalAssignedArticles orderId={subOrder.id} />
              </>
            )}
          </>
        )}

        {isEditMode && currentTab === 'activity' && <OrderActivity orderId={subOrderId} />}
      </div>
    </>
  );
}
