import { PayloadApiError, payloadClient } from '@/core/api';
import { useIntl } from '@tiny-intl/react';
import { useState } from 'react';

import { LoginForm } from '../components/login-form';

export function CertificateLoginPage() {
  const { t } = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  const defaultEmail = import.meta.env.DEV ? import.meta.env.VITE_DEV_LOGIN_EMAIL || '' : '';
  const defaultPassword = import.meta.env.DEV ? import.meta.env.VITE_DEV_LOGIN_PASSWORD || '' : '';

  async function handleLogin(values: { email: string; password: string }) {
    try {
      setIsLoading(true);
      const { token } = await payloadClient.customRequest<{ token: string }>({
        subpath: '/auth/certificates-management/login',
        method: 'POST',
        data: values,
      });

      window.location.href = `${import.meta.env.VITE_CERTIFICATE_SERVICE_URL}?token=${token}`;
    } catch (error) {
      if (error instanceof PayloadApiError) {
        throw error; // Let LoginForm handle all errors
      }
      throw error;
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <div className="grid gap-2 text-center">
        <h1 className="text-3xl font-bold">{t('auth.certificateLogin')}</h1>
        <p className="text-muted-foreground text-balance">{t('auth.certificateLoginDescription')}</p>
      </div>
      <LoginForm
        onSubmit={handleLogin}
        isLoading={isLoading}
        defaultEmail={defaultEmail}
        defaultPassword={defaultPassword}
      />
    </div>
  );
}
