import { z } from 'zod';

export const crewChangeSchema = z.object({
  id: z.string().optional(),
  dateTime: z.string().min(1),
});

export const deploymentSchema = z.object({
  id: z.string().nullable(),
  title: z.string().min(1),
  startDate: z.string().min(1),
  endDate: z.string().min(1),
  crewChanges: z.array(crewChangeSchema).optional(),
  description: z.string().optional(),
});

export type DeploymentFormValues = z.infer<typeof deploymentSchema>;

export type DeploymentData = {
  id?: string | null;
  title: string;
  startDate: string;
  endDate: string;
  crewChanges?: Array<{ id?: string; dateTime: string }>;
  description?: string | null;
};
