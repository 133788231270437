import { ResolvedUser } from '@/shared/components/activity';
import { useIntl } from '@tiny-intl/react';
import { DateTime } from 'luxon';

import { ResolvedFieldValue } from './resolved-field-value';
import type { DeploymentChangeProps } from './types';

export function DeploymentAddedActivity({ activity, order }: DeploymentChangeProps) {
  const { t, locale } = useIntl();

  return (
    <div className="flex flex-col gap-1 border-l-2 border-green-500/20 pl-2">
      <div className="flex items-center gap-1">
        <ResolvedUser userId={activity.user!} />
        <span className="text-muted-foreground">{t('activity.deployment.deploymentAdded')}</span>
        <span className="font-medium">{activity.deploymentTitle}</span>
        <span className="text-muted-foreground">· {DateTime.fromISO(activity.timestamp).toRelative({ locale })}</span>
      </div>

      {activity.changes && activity.changes.length > 0 && (
        <>
          {/* Display start date if available */}
          {activity.changes.find((change) => change.field === 'startDate') && (
            <div className="ml-5 flex items-center gap-1 text-sm">
              <span className="text-muted-foreground">{t('activity.deployment.fields.startDate')}:</span>
              <span className="font-medium">
                <ResolvedFieldValue
                  field="startDate"
                  value={activity.changes.find((change) => change.field === 'startDate')?.to}
                  order={order}
                />
              </span>
            </div>
          )}

          {/* Display end date if available */}
          {activity.changes.find((change) => change.field === 'endDate') && (
            <div className="ml-5 flex items-center gap-1 text-sm">
              <span className="text-muted-foreground">{t('activity.deployment.fields.endDate')}:</span>
              <span className="font-medium">
                <ResolvedFieldValue
                  field="endDate"
                  value={activity.changes.find((change) => change.field === 'endDate')?.to}
                  order={order}
                />
              </span>
            </div>
          )}

          {/* Display description if available */}
          {activity.changes.find((change) => change.field === 'description') && (
            <div className="ml-5 flex items-center gap-1 text-sm">
              <span className="text-muted-foreground">{t('activity.deployment.fields.description')}:</span>
              <span className="font-medium">
                <ResolvedFieldValue
                  field="description"
                  value={activity.changes.find((change) => change.field === 'description')?.to}
                  order={order}
                />
              </span>
            </div>
          )}

          {/* Display any crew changes */}
          {activity.changes
            .filter((change) => change.field.startsWith('crewChange-'))
            .map((change, index) => (
              <div key={index} className="ml-5 flex items-center gap-1 text-sm">
                <span className="text-muted-foreground">{t('activity.deployment.crewChangeAdded')}</span>
                <span className="font-medium">
                  <ResolvedFieldValue field={change.field} value={change.to} order={order} />
                </span>
              </div>
            ))}
        </>
      )}
    </div>
  );
}

export function DeploymentRemovedActivity({ activity }: DeploymentChangeProps) {
  const { t, locale } = useIntl();

  return (
    <div className="flex flex-col gap-1 border-l-2 border-red-500/20 pl-2">
      <div className="flex items-center gap-1">
        <ResolvedUser userId={activity.user!} />
        <span className="text-muted-foreground">{t('activity.deployment.deploymentRemoved')}</span>
        <span className="font-medium">{activity.deploymentTitle}</span>
        <span className="text-muted-foreground">· {DateTime.fromISO(activity.timestamp).toRelative({ locale })}</span>
      </div>
    </div>
  );
}
