import { Link } from 'react-router-dom';

import JohanniterLogoSmall from '/johanniter-logo-small.svg';

export const Logo = () => (
  <Link to="/" className="flex gap-2 items-center p-2 group/logo">
    <div className="flex justify-center items-center size-8 shrink-0 text-primary-foreground">
      <img src={JohanniterLogoSmall} alt="Johanniter Logo" />
    </div>
    <div className="grid text-left text-sm leading-tight group-data-[collapsible=icon]/sidebar:hidden">
      <span className="font-semibold">Johanniter</span>
      <span className="text-xs text-muted-foreground">Offshore Portal</span>
    </div>
  </Link>
);
