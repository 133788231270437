import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/shared/components/ui';
import { useIntl } from '@tiny-intl/react';
import { Globe } from 'lucide-react';
import { Outlet } from 'react-router-dom';

import JohanniterLogo from '/johanniter-logo.svg';
import LoginBgImage from '/login-bg-image.jpg';

export function AuthLayout() {
  const { t, change, locale } = useIntl();

  const handleLocaleChange = (newLocale: string) => {
    change(newLocale);
    localStorage.setItem('selectedLocale', newLocale);
  };

  return (
    <div className="h-screen w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
      <div className="hidden relative bg-muted lg:block">
        <div className="flex-col p-10 h-full text-white lg:flex">
          <div className="relative z-20 mt-auto max-w-lg">
            <img src={JohanniterLogo} alt="johanniter logo" className="mb-4 w-auto h-12" />
            <div className="space-y-2">
              <h2 className="text-xl font-bold">{t('welcome.welcomeTitle')}</h2>
              <p>{t('welcome.welcomeDescription')}</p>
            </div>
          </div>
        </div>

        <img
          src={LoginBgImage}
          alt="Login background"
          width="1920"
          height="1080"
          className="absolute top-0 size-full object-cover dark:brightness-[0.1] dark:grayscale"
        />
      </div>
      <div className="flex justify-center items-center py-12">
        <div className="mx-auto grid w-[350px] gap-6">
          <div
            className={`
              fixed bottom-10 left-1/2 mx-auto -translate-x-1/2
              lg:absolute lg:bottom-[unset] lg:left-[unset] lg:right-10 lg:top-10 lg:mx-0 lg:transform-none
            `}
          >
            <Select value={locale} onValueChange={handleLocaleChange}>
              <SelectTrigger className="h-8 w-[130px]">
                <div className="flex gap-2 items-center">
                  <Globe className="size-4" />
                  <SelectValue />
                </div>
              </SelectTrigger>

              <SelectContent>
                <SelectItem value="de-DE">{t('settings.german')}</SelectItem>
                <SelectItem value="en-US">{t('settings.english')}</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="mb-14 lg:mb-0">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
