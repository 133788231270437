import { useObject, useOrganization } from '@/core/api';
import { useGlobalMetadata } from '@/core/api/global-metadata';
import type { JsonSchemaType } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { DateTime } from 'luxon';

import type { ResolvedFieldValueProps } from './types';

export function ResolvedFieldValue({ field, value, order }: ResolvedFieldValueProps) {
  const { t } = useIntl();
  // Always call hooks unconditionally to preserve hook order.
  const { data: organization } = useOrganization(field === 'organization' && typeof value === 'string' ? value : '');
  const { data: object } = useObject(field === 'object' && typeof value === 'string' ? value : '');
  const { data: globalMetadata } = useGlobalMetadata();

  if (value === null || value === undefined || value === '') {
    return <span className="font-medium">{t('activity.none')}</span>;
  }

  const needsMetadata = field.startsWith('metadata.') || field.startsWith('dynamicMetadata.');

  if (!needsMetadata && field !== 'organization' && field !== 'object') {
    if (
      field === 'startDate' ||
      field === 'endDate' ||
      field === 'crewChange-added' ||
      field === 'crewChange-removed' ||
      field === 'crewChange-modified'
    ) {
      const date = DateTime.fromISO(value as string);
      if (date.isValid) {
        return <span>{date.toFormat('dd.MM.yyyy HH:mm')}</span>;
      }
    }
    return <span>{String(value)}</span>;
  }

  if (field === 'organization' && organization) {
    return <span>{organization.name}</span>;
  }

  if (field === 'object' && object) {
    return <span>{object.name}</span>;
  }

  if (field.startsWith('metadata.') || field.startsWith('dynamicMetadata.')) {
    const [type, key] = field.split('.');
    let metadataSchema: JsonSchemaType | undefined;

    if (type === 'metadata') {
      if (order?.type === 'root-order') {
        metadataSchema = globalMetadata?.rootOrderMetadata;
      } else if (order?.type === 'article-rental') {
        metadataSchema = globalMetadata?.articleRentalOrderMetadata;
      } else if (order?.type === 'deployment-planning') {
        metadataSchema = globalMetadata?.deploymentPlanningMetadata;
      }
    } else if (type === 'dynamicMetadata' && order?.dynamicMetadataSchema) {
      metadataSchema = order.dynamicMetadataSchema;
    }

    if (metadataSchema?.properties[key]) {
      const fieldDef = metadataSchema.properties[key];
      switch (fieldDef.type) {
        case 'date':
          return typeof value === 'string' && DateTime.fromISO(value).isValid ? (
            <span>{DateTime.fromISO(value).toFormat('dd.MM.yyyy')}</span>
          ) : (
            <span>{String(value)}</span>
          );
        case 'boolean':
          return <span>{t(value ? 'common.yes' : 'common.no')}</span>;
        case 'number':
          return <span>{typeof value === 'number' ? value.toString() : String(value)}</span>;
        default:
          return <span>{String(value)}</span>;
      }
    }
  }

  return <span>{String(value)}</span>;
}
