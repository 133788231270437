import { useChangeRequests, useOrder, useOrderVersions } from '@/core/api';
import { useGlobalMetadata } from '@/core/api/global-metadata';
import { ResolvedUser, transformVersionsToActivity } from '@/shared/components/activity';
import { useIntl } from '@tiny-intl/react';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { CustomActivityTimeline } from './activity-timeline';
import { ChangeRequestActivity } from './change-request-activity';
import { DeploymentAddedActivity, DeploymentRemovedActivity } from './deployment-activity';
import { DeploymentChange } from './deployment-change';
import { ResolvedFieldValue } from './resolved-field-value';
import type { ActivityItem, DeploymentType, OrderActivityProps } from './types';
import { getChangeRequestChanges, getDeploymentChanges, getFieldName, getOrderChanges } from './utils';

export function OrderActivity({ orderId }: OrderActivityProps) {
  const { t, locale } = useIntl();
  const {
    data: versionsData,
    isLoading: isLoadingVersions,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useOrderVersions(orderId!, {
    disabled: !orderId,
    limit: 15,
  });

  const { data: order } = useOrder(orderId!);
  const { data: globalMetadata } = useGlobalMetadata();

  // Fetch change requests related to this order's deployments
  const deploymentIds = useMemo(() => {
    return (order?.deployments as DeploymentType[] | undefined)?.map((d) => d.id) || [];
  }, [order?.deployments]);

  const changeRequestWhere = useMemo(() => {
    if (!deploymentIds.length) return undefined;

    return {
      collection: {
        equals: 'orders-deployments',
      },
      item: {
        in: deploymentIds,
      },
    };
  }, [deploymentIds]);

  const { data: changeRequestsData } = useChangeRequests({
    where: changeRequestWhere,
    limit: 100, // Fetch a reasonable number of change requests
    sort: '-createdAt',
  });

  // Map of change request IDs to change requests for quick lookup
  const changeRequestMap = useMemo(() => {
    if (!changeRequestsData?.docs) return new Map();

    return new Map(changeRequestsData.docs.map((cr) => [cr.id, cr]));
  }, [changeRequestsData?.docs]);

  if (isLoadingVersions) {
    return <div className="text-center text-muted-foreground">{t('activity.loading')}</div>;
  }

  if (!versionsData?.pages[0].docs.length) {
    return <div className="text-center text-muted-foreground">{t('activity.noActivity')}</div>;
  }

  // Flatten all pages of versions
  const allDocs = versionsData.pages.flatMap((page) => page.docs);

  // Transform versions to activities
  const orderActivities = transformVersionsToActivity(allDocs, getOrderChanges);

  // Get deployment-related activities
  const deploymentActivities: ActivityItem[] = [];
  const changeRequestActivities: ActivityItem[] = [];

  // Process versions to extract deployment and change request activities
  for (let i = 0; i < allDocs.length - 1; i++) {
    const currentVersion = allDocs[i];
    const previousVersion = allDocs[i + 1];

    // Extract deployment changes
    const deploymentChanges = getDeploymentChanges(currentVersion.version, previousVersion.version);
    deploymentActivities.push(...deploymentChanges);

    // Extract change request changes
    const changeRequestChanges = getChangeRequestChanges(currentVersion.version, previousVersion.version);
    changeRequestActivities.push(...changeRequestChanges);
  }

  // Add activities for accepted and rejected change requests
  if (changeRequestsData?.docs) {
    changeRequestsData.docs.forEach((cr) => {
      if (cr.status === 'approved') {
        // Find the deployment this change request is for
        const deployment = (order?.deployments as DeploymentType[] | undefined)?.find((d) => d.id === cr.item);

        if (deployment) {
          // Handle user ID extraction with proper type checking
          let userId: string | null = null;
          if (typeof cr.reviewedBy === 'string') {
            userId = cr.reviewedBy;
          } else if (cr.reviewedBy && typeof cr.reviewedBy === 'object' && 'id' in cr.reviewedBy) {
            userId = cr.reviewedBy.id;
          } else if (typeof cr.requestedBy === 'string') {
            userId = cr.requestedBy;
          } else if (cr.requestedBy && typeof cr.requestedBy === 'object' && 'id' in cr.requestedBy) {
            userId = cr.requestedBy.id;
          }

          // The original deployment state might be stored in the changes
          // We'll use the current deployment and let extractDeploymentDataForChangeRequest
          // handle finding the original deployment state from the changes when needed
          const changeRequestChanges =
            typeof cr.changes === 'object' && cr.changes !== null ? (cr.changes as Record<string, unknown>) : undefined;

          changeRequestActivities.push({
            id: `change-request-accepted-${cr.id}`,
            type: 'change-request-accepted',
            timestamp: cr.reviewedAt || cr.createdAt,
            user: userId,
            deploymentId: cr.item,
            deploymentTitle: deployment.title,
            changeRequestId: cr.id,
            changeRequestChanges,
          });
        }
      } else if (cr.status === 'rejected') {
        // Find the deployment this change request is for
        const deployment = (order?.deployments as DeploymentType[] | undefined)?.find((d) => d.id === cr.item);

        if (deployment) {
          // Handle user ID extraction with proper type checking
          let userId: string | null = null;
          if (typeof cr.reviewedBy === 'string') {
            userId = cr.reviewedBy;
          } else if (cr.reviewedBy && typeof cr.reviewedBy === 'object' && 'id' in cr.reviewedBy) {
            userId = cr.reviewedBy.id;
          } else if (typeof cr.requestedBy === 'string') {
            userId = cr.requestedBy;
          } else if (cr.requestedBy && typeof cr.requestedBy === 'object' && 'id' in cr.requestedBy) {
            userId = cr.requestedBy.id;
          }

          // The original deployment state might be stored in the changes
          // We'll use the current deployment and let extractDeploymentDataForChangeRequest
          // handle finding the original deployment state from the changes when needed
          const changeRequestChanges =
            typeof cr.changes === 'object' && cr.changes !== null ? (cr.changes as Record<string, unknown>) : undefined;

          changeRequestActivities.push({
            id: `change-request-rejected-${cr.id}`,
            type: 'change-request-rejected',
            timestamp: cr.reviewedAt || cr.createdAt,
            user: userId,
            deploymentId: cr.item,
            deploymentTitle: deployment.title,
            changeRequestId: cr.id,
            changeRequestChanges,
          });
        }
      }
    });
  }

  // Combine all activities and sort by timestamp (newest first)
  const allActivities = [...orderActivities, ...deploymentActivities, ...changeRequestActivities].sort((a, b) => {
    return DateTime.fromISO(b.timestamp).toMillis() - DateTime.fromISO(a.timestamp).toMillis();
  });

  if (!allActivities.length) {
    return <div className="text-center text-muted-foreground">{t('activity.noActivity')}</div>;
  }

  // Filter activities to only show "created" event if there are no more pages to load
  const filteredActivities = allActivities.filter((activity) => {
    if (activity.type === 'created') return !hasNextPage;
    return true;
  });

  if (!filteredActivities.length) {
    return <div className="text-center text-muted-foreground">{t('activity.noActivity')}</div>;
  }

  // Render activity based on its type
  const renderActivity = (activity: ActivityItem) => {
    switch (activity.type) {
      case 'created':
        return (
          <div className="flex items-center gap-1">
            <ResolvedUser userId={activity.user!} />
            <span className="text-muted-foreground">{t('activity.order.created')}</span>
            <span className="text-muted-foreground">
              · {DateTime.fromISO(activity.timestamp).toRelative({ locale })}
            </span>
          </div>
        );

      case 'updated':
        return activity.changes?.map((change, index) => {
          // Special handling for "no changes" case
          if (change.field === 'noChanges') {
            return (
              <div key={index} className="flex items-center gap-1">
                <ResolvedUser userId={activity.user!} />
                <span className="text-muted-foreground">{t('activity.order.savedWithoutChanges')}</span>
                <span className="text-muted-foreground">
                  · {DateTime.fromISO(activity.timestamp).toRelative({ locale })}
                </span>
              </div>
            );
          }

          // Skip if field has been deleted
          const fieldName = getFieldName(change.field, order, globalMetadata, t);
          if (fieldName === null) return null;

          return (
            <div key={index} className="flex items-center gap-1">
              <ResolvedUser userId={activity.user!} />
              <span className="text-muted-foreground">{t('activity.changed')}</span>
              {change.field.startsWith('metadata.') || change.field.startsWith('dynamicMetadata.') ? (
                <span>
                  <span className="text-muted-foreground">{t('activity.metadataField')}</span>
                  <span className="font-medium"> {fieldName}</span>
                </span>
              ) : (
                <span className="font-medium">{fieldName}</span>
              )}
              <span className="text-muted-foreground">{t('activity.from')}</span>
              <span className="font-medium">
                <ResolvedFieldValue field={change.field} value={change.from} order={order} />
              </span>
              <span className="text-muted-foreground">{t('activity.to')}</span>
              <span className="font-medium">
                <ResolvedFieldValue field={change.field} value={change.to} order={order} />
              </span>
              <span className="text-muted-foreground">
                · {DateTime.fromISO(activity.timestamp).toRelative({ locale })}
              </span>
            </div>
          );
        });

      case 'deployment-updated':
        return <DeploymentChange activity={activity} order={order} />;

      case 'deployment-added':
        return <DeploymentAddedActivity activity={activity} order={order} />;

      case 'deployment-removed':
        return <DeploymentRemovedActivity activity={activity} />;

      case 'change-request-created':
      case 'change-request-accepted':
      case 'change-request-rejected':
        return (
          <ChangeRequestActivity
            activity={activity}
            order={order}
            changeRequest={activity.changeRequestId ? changeRequestMap.get(activity.changeRequestId) : undefined}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className="space-y-4">
      <CustomActivityTimeline
        activities={filteredActivities}
        renderActivity={renderActivity}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
      />
    </div>
  );
}
