import {
  orderKeys,
  useChangeRequests,
  useCreateChangeRequest,
  useCreateOrder,
  useOrder,
  useReviewChangeRequest,
  useUpdateOrder,
  useUsers,
} from '@/core/api';
import type { PayloadApiError } from '@/core/api/client';
import { useGlobalMetadata } from '@/core/api/global-metadata';
import { useAuth } from '@/core/auth';
import { PageHeader } from '@/shared/components/layout/page-header';
import { TabNav } from '@/shared/components/layout/tab-nav';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Separator,
} from '@/shared/components/ui';
import { ExpandCollapseButtons } from '@/shared/components/ui/expand-collapse-buttons';
import { useTablePreferences } from '@/shared/services/table-preferences';
import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from '@tiny-intl/react';
import { PlusCircle } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { DeploymentDrawer } from '../components/deployment-drawer';
import { DeploymentTable } from '../components/deployment-table';
import { DeploymentTimeline, type DeploymentTimelineHandle } from '../components/deployment-timeline';
import { OrderActivity } from '../components/order-activity';
import { OrderForm } from '../components/order-form';
import { ViewSelect } from '../components/view-select';
import type { DeploymentData, DeploymentFormValues } from '../schema/deployment-schema';
import type { OrderFormData } from '../types/order-form';

export function DeploymentPlanningDetails() {
  const { t } = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: parentId, subOrderId } = useParams();
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const isEditMode = subOrderId !== 'new';
  const { data: parentOrder } = useOrder(parentId!);

  const { data: subOrder } = useOrder(subOrderId!, { disabled: !isEditMode });

  const [error, setError] = useState<PayloadApiError | undefined>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editingDeployment, setEditingDeployment] = useState<DeploymentFormValues | undefined>();
  const [deploymentToDelete, setDeploymentToDelete] = useState<DeploymentFormValues | undefined>();

  const tablePreferences = useTablePreferences({
    tableId: 'deployment-view',
    defaultPreferences: {
      view: 'table',
    },
  });

  // Load initial preferences
  const preferences = tablePreferences.loadPreferences();
  const [view, setView] = useState<'table' | 'timeline'>((preferences.view as 'table' | 'timeline') || 'table');

  // Save preferences when they change
  useEffect(() => {
    tablePreferences.savePreferences({
      view,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  const createOrder = useCreateOrder();
  const updateOrder = useUpdateOrder();
  const createChangeRequest = useCreateChangeRequest();
  const reviewChangeRequest = useReviewChangeRequest();
  const isJuhUser = user?.role === 'juh-admin' || user?.role === 'juh-employee';
  const isJuhAdmin = user?.role === 'juh-admin';
  const basePath = isJuhUser ? '/juh/order-management/orders' : '/c/my-orders';
  const { data: globalMetadata } = useGlobalMetadata();

  const currentTab = location.pathname.split('/').pop();
  const showActions = !isEditMode || currentTab === 'general';

  const tabs = [
    { value: 'general', label: t('common.general') },
    ...(isJuhAdmin ? [{ value: 'activity', label: t('common.activity') }] : []),
  ];

  // Query change requests for all deployments in the order
  const { data: changeRequests } = useChangeRequests(
    subOrder?.deployments?.length
      ? {
          where: {
            and: [
              {
                collection: {
                  equals: 'orders-deployments',
                },
              },
              {
                item: {
                  in: subOrder.deployments.map((d) => d.id!),
                },
              },
              {
                status: {
                  equals: 'pending',
                },
              },
            ],
          },
          sort: '-requestedAt',
        }
      : undefined,
  );

  // Query user data for all change requests
  const requestedByUserIds = Array.from(new Set(changeRequests?.docs.map((cr) => cr.requestedBy)));
  const { data: requestedByUsers } = useUsers(
    requestedByUserIds.length
      ? {
          where: {
            id: {
              in: requestedByUserIds,
            },
          },
        }
      : undefined,
  );

  // Create a map of user IDs to user data for easier lookup
  const userMap = requestedByUsers?.docs.reduce(
    (acc, user) => {
      acc[user.id] = user;
      return acc;
    },
    {} as Record<string, (typeof requestedByUsers.docs)[0]>,
  );

  const handleSubmit = async (data: OrderFormData) => {
    try {
      setError(undefined);
      if (isEditMode && subOrder) {
        if (!isJuhAdmin) {
          if (isJuhUser) {
            toast.error(t('common.insufficientPermissions'));
            return;
          }
          await updateOrder.mutateAsync({
            id: subOrder.id,
            data: {
              metadata: data.metadata,
              dynamicMetadataValues: data.dynamicMetadataValues,
            },
          });
        } else {
          await updateOrder.mutateAsync({ id: subOrder.id, data });
        }
      } else {
        if (!isJuhAdmin) {
          toast.error(t('common.insufficientPermissions'));
          return;
        }
        const response = await createOrder.mutateAsync({
          ...data,
          type: 'deployment-planning',
          parent: parentOrder!.id,
          organization: parentOrder!.organization,
          object: parentOrder!.object,
        });
        navigate(`${basePath}/${parentOrder!.id}/sub-orders/${response.doc.id}/deployment-planning/general`);
      }
      toast.success(t('common.savedSuccessfully'));
    } catch (err) {
      setError(err as PayloadApiError);
      toast.error(t('common.errorSaving'));
    }
  };

  const handleAddDeployment = () => {
    setEditingDeployment(undefined);
    setIsDrawerOpen(true);
  };

  const handleEditDeployment = (deployment: DeploymentData) => {
    setEditingDeployment({
      id: deployment.id || null,
      title: deployment.title,
      startDate: deployment.startDate,
      endDate: deployment.endDate,
      crewChanges: deployment.crewChanges || [],
      description: deployment.description || '',
    });
    setIsDrawerOpen(true);
  };

  const handleSubmitDeployment = async (data: DeploymentFormValues) => {
    if (!subOrder) return;

    if (!isJuhAdmin && isJuhUser) {
      toast.error(t('common.insufficientPermissions'));
      return;
    }

    if (isJuhUser) {
      const currentDeployments = subOrder.deployments || [];
      let newDeployments;

      if (editingDeployment) {
        // Update existing deployment
        newDeployments = currentDeployments.map((d) => (d.id === editingDeployment.id ? { ...data, id: d.id } : d));
      } else {
        // Add new deployment
        newDeployments = [...currentDeployments, { ...data, id: crypto.randomUUID() }];
      }

      await updateOrder.mutateAsync({
        id: subOrder.id,
        data: {
          ...subOrder,
          deployments: newDeployments,
        },
      });
    } else {
      if (!editingDeployment?.id) {
        throw new Error('Editing deployment is required');
      }

      // Create change request for customer users
      await createChangeRequest.mutateAsync({
        organization: subOrder.organization as string,
        collection: 'orders-deployments',
        item: editingDeployment.id,
        changes: data,
      });
    }

    queryClient.invalidateQueries({ queryKey: orderKeys.detail(subOrderId!) });
    setIsDrawerOpen(false);
  };

  const handleAcceptChangeRequest = async (id: string) => {
    await reviewChangeRequest.mutateAsync({ id, action: 'accept' });
    toast.success(t('orders.changeRequestAcceptedSuccessfully'));
    setIsDrawerOpen(false);
    // Invalidate the order query to refresh the deployments
    queryClient.invalidateQueries({ queryKey: orderKeys.detail(subOrderId!) });
  };

  const handleRejectChangeRequest = async (id: string) => {
    try {
      await reviewChangeRequest.mutateAsync({ id, action: 'reject' });
      toast.success(t('orders.changeRequestRejectedSuccessfully'));
      setIsDrawerOpen(false);
    } catch (err) {
      toast.error(t('common.errorSaving'));
    }
  };

  const handleDeleteDeployment = (deployment: DeploymentData) => {
    setDeploymentToDelete({
      id: deployment.id || null,
      title: deployment.title,
      startDate: deployment.startDate,
      endDate: deployment.endDate,
      description: deployment.description || '',
    });
  };

  const confirmDeleteDeployment = async () => {
    if (!subOrder || !deploymentToDelete) return;

    try {
      const newDeployments = (subOrder.deployments || []).filter((d) => d.id !== deploymentToDelete.id);

      await updateOrder.mutateAsync({
        id: subOrder.id,
        data: {
          ...subOrder,
          deployments: newDeployments,
        },
      });

      setDeploymentToDelete(undefined);
      toast.success(t('orders.deploymentDeleted'));
    } catch (err) {
      toast.error(t('common.errorSaving'));
    }
  };

  const timelineRef = useRef<DeploymentTimelineHandle>(null);

  if (!parentOrder || (isEditMode && !subOrder)) {
    return null;
  }

  // Check both global and dynamic metadata schemas for editable fields
  const hasEditableMetadata = Boolean(
    // Check global metadata schema
    (globalMetadata?.deploymentPlanningMetadata?.properties &&
      Object.entries(globalMetadata.deploymentPlanningMetadata.properties).some(
        ([, prop]: [string, { customerAccess?: string }]) => prop.customerAccess === 'read-and-write',
      )) ||
      // Check dynamic metadata schema
      (subOrder?.dynamicMetadataSchema?.properties &&
        Object.entries(subOrder.dynamicMetadataSchema.properties).some(
          ([, prop]: [string, { customerAccess?: string }]) => prop.customerAccess === 'read-and-write',
        )),
  );

  return (
    <>
      <PageHeader
        title={isJuhUser ? t(isEditMode ? 'orders.editSubOrder' : 'orders.newSubOrder') : t('orders.subOrderDetails')}
        backHref={`${basePath}/${parentId}`}
        primaryAction={
          showActions
            ? isJuhUser
              ? {
                  label: isEditMode ? t('common.update') : t('common.create'),
                  loadingLabel: isEditMode ? t('common.updating') : t('common.creating'),
                  form: 'sub-order-form',
                  type: 'submit',
                  disabled: (isEditMode ? updateOrder.isPending : createOrder.isPending) || !isJuhAdmin,
                  isLoading: isEditMode ? updateOrder.isPending : createOrder.isPending,
                }
              : hasEditableMetadata
                ? {
                    label: t('common.update'),
                    loadingLabel: t('common.updating'),
                    form: 'sub-order-form',
                    type: 'submit',
                    disabled: updateOrder.isPending,
                    isLoading: updateOrder.isPending,
                  }
                : undefined
            : undefined
        }
        secondaryAction={
          showActions && isJuhUser
            ? {
                label: t('common.discard'),
                onClick: () => navigate(`${basePath}/${parentId}`),
                variant: 'outline',
              }
            : undefined
        }
      />

      {isEditMode && (
        <TabNav tabs={tabs} basePath={`${basePath}/${parentId}/sub-orders/${subOrderId}/deployment-planning`} />
      )}

      <div className="grid gap-6">
        {(!isEditMode || currentTab === 'general') && (
          <>
            <OrderForm
              id="sub-order-form"
              defaultValues={subOrder || { organization: parentOrder.organization }}
              onSubmit={handleSubmit}
              error={error}
              orderType="deployment-planning"
              parentStartDate={parentOrder.startDate}
              parentEndDate={parentOrder.endDate}
            />

            {isEditMode && subOrder && (
              <>
                <Separator />
                <div className="space-y-4 overflow-hidden">
                  <div className="flex items-center justify-between">
                    <div>
                      <h2 className="text-lg font-semibold">{t('orders.deployments')}</h2>
                      <p className="text-sm text-muted-foreground">{t('orders.deploymentsDescription')}</p>
                    </div>
                    <div className="flex items-center gap-4">
                      {view === 'timeline' && (
                        <ExpandCollapseButtons
                          onExpandAll={() => timelineRef.current?.handleExpandAll()}
                          onCollapseAll={() => timelineRef.current?.handleCollapseAll()}
                        />
                      )}
                      <ViewSelect view={view} onViewChange={(value) => setView(value)} />
                      {isJuhUser && (
                        <Button onClick={handleAddDeployment} disabled={!isJuhAdmin}>
                          <PlusCircle className="size-4 mr-2" />
                          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                            {t('orders.addDeployment')}
                          </span>
                        </Button>
                      )}
                    </div>
                  </div>

                  {!subOrder.deployments?.length ? (
                    <p className="text-center text-sm text-muted-foreground py-8">{t('common.noResults')}</p>
                  ) : view === 'table' ? (
                    <DeploymentTable
                      deployments={subOrder.deployments.map((d) => ({
                        ...d,
                        crewChanges:
                          d.crewChanges?.map((c) => ({
                            id: c.id || undefined,
                            dateTime: c.dateTime,
                          })) || undefined,
                      }))}
                      onEdit={handleEditDeployment}
                      onDelete={isJuhUser ? handleDeleteDeployment : undefined}
                      pendingChangeRequests={changeRequests?.docs.reduce(
                        (acc, request) => {
                          const user = userMap?.[request.requestedBy as string];
                          if (user) {
                            acc[request.item as string] = {
                              requestedBy: {
                                name: `${user.firstName} ${user.lastName}`.trim(),
                              },
                              requestedAt: request.requestedAt,
                            };
                          }
                          return acc;
                        },
                        {} as Record<string, { requestedBy: { name: string }; requestedAt: string }>,
                      )}
                    />
                  ) : (
                    <DeploymentTimeline
                      ref={timelineRef}
                      deployments={subOrder.deployments}
                      startDate={subOrder.startDate}
                      endDate={subOrder.endDate}
                      onEdit={handleEditDeployment}
                      onDelete={isJuhUser ? handleDeleteDeployment : undefined}
                      pendingChangeRequests={changeRequests?.docs.reduce(
                        (acc, request) => {
                          const user = userMap?.[request.requestedBy as string];
                          if (user) {
                            acc[request.item as string] = {
                              requestedBy: {
                                name: `${user.firstName} ${user.lastName}`.trim(),
                              },
                              requestedAt: request.requestedAt,
                            };
                          }
                          return acc;
                        },
                        {} as Record<string, { requestedBy: { name: string }; requestedAt: string }>,
                      )}
                    />
                  )}
                </div>
              </>
            )}
          </>
        )}

        {isEditMode && currentTab === 'activity' && <OrderActivity orderId={subOrderId} />}
      </div>

      {subOrder && (
        <DeploymentDrawer
          open={isDrawerOpen}
          onOpenChange={setIsDrawerOpen}
          onSubmit={handleSubmitDeployment}
          defaultValues={editingDeployment}
          parentStartDate={subOrder.startDate}
          orderStartDate={subOrder.startDate}
          orderEndDate={subOrder.endDate}
          existingDeployments={subOrder.deployments?.map((d) => ({
            id: d.id!,
            startDate: d.startDate,
            endDate: d.endDate,
          }))}
          changeRequest={
            editingDeployment?.id && changeRequests?.docs
              ? (() => {
                  const request = changeRequests.docs.find((r) => r.item === editingDeployment.id);
                  const user = request && userMap?.[request.requestedBy as string];
                  if (!request || !user) return undefined;

                  return {
                    id: request.id,
                    changes: request.changes as DeploymentFormValues,
                    requestedBy: {
                      name: `${user.firstName} ${user.lastName}`.trim(),
                    },
                    requestedAt: request.requestedAt,
                  };
                })()
              : undefined
          }
          onAcceptChangeRequest={handleAcceptChangeRequest}
          onRejectChangeRequest={handleRejectChangeRequest}
        />
      )}

      {isJuhUser && (
        <AlertDialog open={!!deploymentToDelete} onOpenChange={(open) => !open && setDeploymentToDelete(undefined)}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>{t('orders.deleteDeployment')}</AlertDialogTitle>
              <AlertDialogDescription>
                {t('orders.deleteDeploymentConfirmation', { description: deploymentToDelete?.description || '' })}
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
              <AlertDialogAction onClick={confirmDeleteDeployment}>{t('common.delete')}</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </>
  );
}
