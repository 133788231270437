import { Button } from '@/shared/components/ui/button';
import { Calendar } from '@/shared/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/shared/components/ui/popover';
import { cn } from '@/shared/utils';
import { useIntl } from '@tiny-intl/react';
import { CalendarIcon, X } from 'lucide-react';
import { DateTime } from 'luxon';
import { forwardRef } from 'react';

export const DatePicker = forwardRef<
  HTMLDivElement,
  {
    date?: Date | null;
    setDate: (date?: Date | null) => void;
    placeholder?: string;
    disabled?: boolean;
    defaultMonth?: Date | null;
    minDate?: Date;
    maxDate?: Date;
    disabledRanges?: Array<{ start: Date; end: Date }>;
    onBlur?: (e: React.FocusEvent<HTMLButtonElement>) => void;
  }
>(function DatePickerCmp(
  { date, setDate, placeholder, disabled = false, defaultMonth, minDate, maxDate, disabledRanges, onBlur },
  ref,
) {
  const { locale } = useIntl();

  return (
    <div className="relative flex w-full">
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={'outline'}
            className={cn('w-full justify-start px-3 text-left font-normal', !date && 'text-muted-foreground')}
            disabled={disabled}
          >
            <CalendarIcon className="size-4" />
            {date ? (
              DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_MED, {
                locale,
              })
            ) : (
              <span>{placeholder}</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" ref={ref}>
          <Calendar
            mode="single"
            selected={date || undefined}
            onSelect={(v) => {
              setDate(v);
              if (onBlur) {
                onBlur({
                  target: {
                    value: v,
                  },
                } as unknown as React.FocusEvent<HTMLButtonElement>);
              }
            }}
            initialFocus
            defaultMonth={defaultMonth || undefined}
            minDate={minDate}
            maxDate={maxDate}
            disabledRanges={disabledRanges}
          />
        </PopoverContent>
      </Popover>
      {date && (
        <Button
          variant="ghost"
          size="icon"
          className="absolute right-0 h-full px-2 hover:bg-transparent w-[42px] group"
          onClick={(e) => {
            e.preventDefault();
            setDate(null);
          }}
        >
          <X className="size-4 opacity-50 group-hover:opacity-100" />
        </Button>
      )}
    </div>
  );
});
