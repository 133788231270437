import { useOrders, useOrganization } from '@/core/api';
import { useAuth } from '@/core/auth';
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/shared/components/ui';
import { TimelineView } from '@/shared/components/ui/timeline';
import { cn } from '@/shared/utils';
import type { Order } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { ChevronDown, ChevronRight, MoreHorizontal } from 'lucide-react';
import { DateTime } from 'luxon';
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import type { Id } from 'react-calendar-timeline';
import { useNavigate } from 'react-router-dom';

interface OrdersTimelineProps {
  orders: Order[];
  groupByOrganization?: boolean;
  onDelete?: (order: Order) => void;
}

export interface OrdersTimelineHandle {
  handleExpandAll: () => void;
  handleCollapseAll: () => void;
}

function OrganizationGroup({ id, isExpanded, onToggle }: { id: string; isExpanded?: boolean; onToggle?: () => void }) {
  const { data: organization } = useOrganization(id);
  const { data: rootOrders } = useOrders({
    where: {
      organization: {
        equals: id,
      },
      parent: {
        exists: false,
      },
    },
  });

  const hasRootOrders = (rootOrders?.docs.length ?? 0) > 0;

  return (
    <button
      onClick={hasRootOrders && onToggle ? onToggle : undefined}
      className="flex items-center gap-1 w-full text-left text-sm hover:bg-accent/50 rounded px-1"
      disabled={!hasRootOrders}
    >
      {hasRootOrders ? (
        <span className="text-muted-foreground">
          {isExpanded ? <ChevronDown className="size-4" /> : <ChevronRight className="size-4" />}
        </span>
      ) : (
        <span className="w-5" /> // Spacer for alignment
      )}
      <span className="truncate">{organization?.name || '-'}</span>
    </button>
  );
}

function RootOrderGroup({
  order,
  isExpanded,
  onToggle,
}: {
  order: Order;
  isExpanded?: boolean;
  onToggle?: () => void;
}) {
  const { data: subOrders } = useOrders({
    where: {
      parent: {
        equals: order.id,
      },
    },
  });

  const hasSubOrders = (subOrders?.docs.length ?? 0) > 0;

  return (
    <button
      onClick={hasSubOrders && onToggle ? onToggle : undefined}
      className="flex items-center gap-1 pl-4 w-full text-left text-sm hover:bg-accent/50 rounded px-1"
      disabled={!hasSubOrders}
    >
      {hasSubOrders ? (
        <span className="text-muted-foreground">
          {isExpanded ? <ChevronDown className="size-4" /> : <ChevronRight className="size-4" />}
        </span>
      ) : (
        <span className="w-5" /> // Spacer for alignment
      )}
      <span className="truncate">{order.title}</span>
    </button>
  );
}

export const OrdersTimeline = forwardRef<OrdersTimelineHandle, OrdersTimelineProps>(
  ({ orders, groupByOrganization = false, onDelete }, ref) => {
    const navigate = useNavigate();
    const { t } = useIntl();
    const { user } = useAuth();
    const isJuhUser = user?.role === 'juh-admin' || user?.role === 'juh-employee';
    const isJuhAdmin = user?.role === 'juh-admin';
    const basePath = isJuhUser ? '/juh/order-management/orders' : '/c/my-orders';

    // Initialize expandedOrgs with all organizations expanded
    const initialExpandedOrgs = useMemo(() => {
      if (!groupByOrganization) return {};
      return orders.reduce(
        (acc, order) => {
          const orgId = typeof order.organization === 'string' ? order.organization : order.organization.id;
          acc[orgId] = true;
          return acc;
        },
        {} as Record<string, boolean>,
      );
    }, [orders, groupByOrganization]);

    const [expandedOrgs, setExpandedOrgs] = useState<Record<string, boolean>>(initialExpandedOrgs);
    const [expandedOrders, setExpandedOrders] = useState<Record<string, boolean>>({});

    const toggleOrg = (id: string) => {
      setExpandedOrgs((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    };

    const toggleOrder = (id: string) => {
      setExpandedOrders((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    };

    const handleExpandAll = () => {
      // Expand all organizations
      const allOrgs = orders.reduce(
        (acc, order) => {
          const orgId = typeof order.organization === 'string' ? order.organization : order.organization.id;
          acc[orgId] = true;
          return acc;
        },
        {} as Record<string, boolean>,
      );

      // Expand all root orders
      const allRootOrders = orders
        .filter((order) => !order.parent)
        .reduce(
          (acc, order) => {
            acc[order.id] = true;
            return acc;
          },
          {} as Record<string, boolean>,
        );

      setExpandedOrgs(allOrgs);
      setExpandedOrders(allRootOrders);
    };

    const handleCollapseAll = () => {
      setExpandedOrgs({});
      setExpandedOrders({});
    };

    useImperativeHandle(ref, () => ({
      handleExpandAll,
      handleCollapseAll,
    }));

    const handleNavigate = (order: Order) => {
      if (order.parent) {
        const parentId = typeof order.parent === 'string' ? order.parent : order.parent.id;
        navigate(`${basePath}/${parentId}/sub-orders/${order.id}/${order.type}`);
      } else {
        navigate(`${basePath}/${order.id}`);
      }
    };

    const { timelineGroups, timelineItems } = groupByOrganization
      ? // Group by organization
        {
          timelineGroups: Object.values(
            orders.reduce(
              (acc, order) => {
                const orgId = typeof order.organization === 'string' ? order.organization : order.organization.id;

                if (!acc[orgId]) {
                  acc[orgId] = {
                    id: orgId,
                    title: null,
                    orders: [],
                  };
                }
                acc[orgId].orders.push(order);
                return acc;
              },
              {} as Record<string, { id: string; title: null; orders: Order[] }>,
            ),
          )
            .map((org) => {
              const rootOrders = org.orders.filter((order) => !order.parent);
              return [
                // Organization group
                {
                  id: `org-${org.id}` as Id,
                  title: (
                    <OrganizationGroup
                      id={org.id}
                      isExpanded={expandedOrgs[org.id]}
                      onToggle={() => toggleOrg(org.id)}
                    />
                  ),
                  root: true,
                  height: 40,
                },
                // Root orders and their sub-orders if org is expanded
                ...(expandedOrgs[org.id]
                  ? rootOrders.flatMap((rootOrder) => [
                      // Root order
                      {
                        id: rootOrder.id as Id,
                        title: (
                          <RootOrderGroup
                            order={rootOrder}
                            isExpanded={expandedOrders[rootOrder.id]}
                            onToggle={() => toggleOrder(rootOrder.id)}
                          />
                        ),
                        root: false,
                      },
                      // Sub-orders if root order is expanded
                      ...(expandedOrders[rootOrder.id]
                        ? org.orders
                            .filter((order) => order.parent === rootOrder.id)
                            .map((subOrder) => ({
                              id: subOrder.id as Id,
                              title: <div className="pl-9 text-sm truncate">{subOrder.title}</div>,
                              root: false,
                            }))
                        : []),
                    ])
                  : []),
              ];
            })
            .flat(),
          timelineItems: orders
            .filter((order) => {
              if (!order.parent) {
                // Show root orders when org is expanded
                const orgId = typeof order.organization === 'string' ? order.organization : order.organization.id;
                return expandedOrgs[orgId];
              } else {
                // Show sub-orders when both org and parent order are expanded
                const orgId = typeof order.organization === 'string' ? order.organization : order.organization.id;
                const parentId = typeof order.parent === 'string' ? order.parent : order.parent.id;
                return expandedOrgs[orgId] && expandedOrders[parentId];
              }
            })
            .map((order) => ({
              id: order.id,
              group: order.id,
              title: (
                <div className="flex items-center gap-2 w-full justify-between">
                  <span>{order.title}</span>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" className="size-4 p-0 text-muted-foreground hover:text-foreground">
                        <span className="sr-only">{t('navigation.openMenu')}</span>
                        <MoreHorizontal className="size-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          handleNavigate(order);
                        }}
                      >
                        {isJuhUser ? t('common.edit') : t('common.view')}
                      </DropdownMenuItem>
                      {isJuhAdmin && onDelete && (
                        <DropdownMenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            onDelete(order);
                          }}
                        >
                          {t('common.delete')}
                        </DropdownMenuItem>
                      )}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              ),
              start_time: DateTime.fromISO(order.startDate!).toMillis(),
              end_time: DateTime.fromISO(order.endDate!).toMillis(),
              className: cn('!bg-muted !border-none'),
            })),
        }
      : // Show each order as its own group
        {
          timelineGroups: orders.map((order) => ({
            id: order.id,
            title: <div className="ml-2 text-sm truncate">{order.title}</div>,
          })),
          timelineItems: orders.map((order) => ({
            id: order.id,
            group: order.id,
            title: (
              <div className="flex items-center gap-2 w-full justify-between">
                <span>{order.title}</span>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" className="size-4 p-0 text-muted-foreground hover:text-foreground">
                      <span className="sr-only">{t('navigation.openMenu')}</span>
                      <MoreHorizontal className="size-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleNavigate(order);
                      }}
                    >
                      {isJuhUser ? t('common.edit') : t('common.view')}
                    </DropdownMenuItem>
                    {isJuhAdmin && onDelete && (
                      <DropdownMenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          onDelete(order);
                        }}
                      >
                        {t('common.delete')}
                      </DropdownMenuItem>
                    )}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            ),
            start_time: DateTime.fromISO(order.startDate!).toMillis(),
            end_time: DateTime.fromISO(order.endDate!).toMillis(),
            className: cn('!bg-muted !border-none'),
          })),
        };

    return (
      <TimelineView
        groups={timelineGroups}
        items={timelineItems}
        startTime={DateTime.now().minus({ months: 1 }).toMillis()}
        endTime={DateTime.now().plus({ months: 12 }).toMillis()}
        minZoom={30 * 24 * 60 * 60 * 1000}
        maxZoom={365 * 24 * 60 * 60 * 1000}
        horizontalLineClassNamesForGroup={(group) => (group.root ? ['row-root'] : [])}
      />
    );
  },
);

OrdersTimeline.displayName = 'OrdersTimeline';
