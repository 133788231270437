import { useAuth } from '@/core/auth';
import { PageHeading } from '@/shared/components/layout';
import { ArticleTable } from '@/shared/components/tables';
import { useIntl } from '@tiny-intl/react';

export function ArticleOverview() {
  const { t } = useIntl();
  const { user } = useAuth();
  const isJuhUser = user?.role === 'juh-admin' || user?.role === 'juh-employee';

  return (
    <>
      <div className="flex items-center justify-between">
        <PageHeading>{isJuhUser ? t('stock.stock') : t('articles.myArticles')}</PageHeading>
      </div>
      <ArticleTable
        tableId={`article-overview-${isJuhUser ? 'juh' : 'user'}`}
        readOnly={!isJuhUser}
        hideFilters={!isJuhUser}
        where={
          !isJuhUser
            ? {
                or: [
                  {
                    'template.isContainer': {
                      equals: true,
                    },
                  },
                  {
                    parent: {
                      equals: null,
                    },
                  },
                ],
              }
            : {}
        }
      />
    </>
  );
}
