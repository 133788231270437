import { useDeleteArticle, useUpdateArticle } from '@/core/api';
import { useAuth } from '@/core/auth';
import { ArticleTable } from '@/shared/components/tables';
import {
  Button,
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  ScrollArea,
} from '@/shared/components/ui';
import type { Article } from '@johanniter-offshore/backend';
import { useIntl } from '@tiny-intl/react';
import { ArrowRight, MoreHorizontal, PlusCircle } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import { ArticleActions } from './sub-order-article-actions';

interface ArticleRentalAssignedArticlesProps {
  orderId: string;
}

export function ArticleRentalAssignedArticles({ orderId }: ArticleRentalAssignedArticlesProps) {
  const { t } = useIntl();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const updateArticle = useUpdateArticle();
  const deleteArticle = useDeleteArticle();
  const { user } = useAuth();
  const isJuhUser = user?.role === 'juh-admin' || user?.role === 'juh-employee';
  const readOnly = !isJuhUser;

  const handleAssignArticle = async (article: Article) => {
    if (readOnly) return;
    try {
      await updateArticle.mutateAsync({
        id: article.id,
        data: {
          order: orderId,
        },
      });
      toast.success(t('articles.articleAssignedToOrder'));
    } catch (error) {
      toast.error(t('articles.errorSavingArticleDescription'));
    }
  };

  const handleUnassignArticle = async (article: Article) => {
    if (readOnly) return;
    try {
      await updateArticle.mutateAsync({
        id: article.id,
        data: {
          order: null,
        },
      });
      toast.success(t('articles.articleRemovedFromOrder'));
    } catch (error) {
      toast.error(t('articles.errorSavingArticleDescription'));
    }
  };

  const handleDeleteArticle = async (article: Article) => {
    if (readOnly) return;
    try {
      await deleteArticle.mutateAsync(article.id);
      toast.success(t('articles.articleDeletedSuccessfully'));
    } catch (error) {
      toast.error(t('articles.errorDeletingArticle'));
    }
  };

  const customCellActions = (article: Article) => {
    if (readOnly) {
      return (
        <Button
          variant="ghost"
          size="icon"
          onClick={() => navigate(`/c/my-articles/${article.id}`)}
          aria-label={t('common.view')}
        >
          <ArrowRight className="size-4" />
        </Button>
      );
    }

    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="size-8 p-0">
            <span className="sr-only">{t('navigation.openMenu')}</span>
            <MoreHorizontal className="size-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>{t('common.actions')}</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => navigate(`/juh/article-management/articles/${article.id}`)}>
            {t('common.edit')}
          </DropdownMenuItem>
          {!article.parent && (
            <DropdownMenuItem onClick={() => handleUnassignArticle(article)}>
              {t('articles.unassignArticle')}
            </DropdownMenuItem>
          )}
          <DropdownMenuItem onClick={() => handleDeleteArticle(article)}>{t('common.delete')}</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-lg font-semibold">{t('orders.assignedArticles')}</h2>
          <p className="text-sm text-muted-foreground">{t('orders.articleRental.assignExistingArticlesDescription')}</p>
        </div>
      </div>

      <ArticleTable
        tableId={`article-rental-assigned-articles-${orderId}-${isJuhUser ? 'juh' : 'user'}`}
        readOnly={!isJuhUser}
        hideFilters={!isJuhUser}
        where={{
          order: { equals: orderId },
          ...(!isJuhUser
            ? {
                or: [
                  {
                    'template.isContainer': {
                      equals: true,
                    },
                  },
                  {
                    parent: {
                      equals: null,
                    },
                  },
                ],
              }
            : {}),
        }}
        hideOrder
        customCellActions={customCellActions}
        customActions={
          !readOnly ? <ArticleActions orderId={orderId} onAssignArticles={() => setIsDrawerOpen(true)} /> : <></>
        }
      />

      {!readOnly && (
        <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen} direction="right">
          <DrawerContent className="left-auto right-0 top-0 mt-0 h-screen w-full rounded-none max-w-[1200px]">
            <ScrollArea className="h-screen">
              <DrawerHeader>
                <DrawerTitle>{t('orders.articleRental.assignExistingArticles')}</DrawerTitle>
                <DrawerDescription>{t('orders.articleRental.assignExistingArticlesDescription')}</DrawerDescription>
              </DrawerHeader>
              <div className="p-4 space-y-4">
                <ArticleTable
                  tableId={`article-rental-assigned-articles-${orderId}-${isJuhUser ? 'juh' : 'user'}`}
                  customActions={<></>}
                  where={{
                    order: { equals: null },
                    parent: { equals: null },
                    id: {
                      not_equals: orderId,
                    },
                  }}
                  hideFilters={false}
                  hideActions={false}
                  hideOrder
                  customCellActions={(article) => (
                    <Button onClick={() => handleAssignArticle(article)} size="sm">
                      <PlusCircle className="size-4" />
                      {t('common.add')}
                    </Button>
                  )}
                />
              </div>
            </ScrollArea>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
}
