import { useIntl } from '@tiny-intl/react';
import { ChevronsDownUp, ChevronsUpDown } from 'lucide-react';

import { Button } from './button';

interface ExpandCollapseButtonsProps {
  onExpandAll: () => void;
  onCollapseAll: () => void;
}

export function ExpandCollapseButtons({ onExpandAll, onCollapseAll }: ExpandCollapseButtonsProps) {
  const { t } = useIntl();

  return (
    <div className="inline-flex rounded-md border  h-9">
      <Button
        variant="ghost"
        size="icon"
        onClick={onExpandAll}
        aria-label={t('common.expandAll')}
        className="rounded-r-none border-r"
      >
        <ChevronsUpDown className="size-4" />
      </Button>
      <Button
        variant="ghost"
        size="icon"
        onClick={onCollapseAll}
        aria-label={t('common.collapseAll')}
        className="rounded-l-none"
      >
        <ChevronsDownUp className="size-4" />
      </Button>
    </div>
  );
}
